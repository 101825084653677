import { useSelector } from "react-redux";
import { List } from "antd";
import ScienceCard from "./ScienceCard";
import TextBubble from "../Bubble/TextBubble";
import BubbleToolbar from "../BubbleToolbar";
import styles from "./index.module.less";

function ScienceBubble({ text, status, finished, createdAt, setComposerText, messageId, isLast }) {
  const { checking } = useSelector((state) => state.user);

  try {
    const itemList = JSON.parse(text);
    return (
      <List className={styles.scienceBubble}>
        <List.Item
          className="result-reply"
          content={`以下是为您找到的 ${itemList.length} 份数据`}
        />
        {itemList.map((item, index) => {
          return (
            <List.Item
              key={`listitem-${index}`}
              children={
                <ScienceCard
                  props={item}
                  index={index + 1}
                />
              }
            />
          );
        })}
        {!checking && finished && (
          <BubbleToolbar
            text={text}
            createdAt={createdAt}
            setComposerText={setComposerText}
            messageId={messageId}
            isLast={isLast}
            position={"left"}
            isScholar={true}
          />
        )}
      </List>
    );
  } catch (error) {
    return (
      <TextBubble
        text={text}
        status={status}
        finished={finished}
        createdAt={createdAt}
        setComposerText={setComposerText}
        messageId={messageId}
      />
    );
  }
}

export default ScienceBubble;
