import { configResponsive, useResponsive } from "ahooks";

configResponsive({
  small: 0,
  middle: 992,
  large: 1200,
  xlarge: 1920,
});

export default function useScreenSize() {
  const { small, middle, large, xlarge } = useResponsive();
  return { small, middle, large, xlarge };
}
