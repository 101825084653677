import { Fragment, useCallback, useState } from "react";
import { Button, Descriptions, Popover, Tooltip, Typography, message } from "antd";
import { CheckOutlined, CodeOutlined, CopyOutlined, EyeOutlined } from "@ant-design/icons";
import cls from "classnames";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import copy from "copy-to-clipboard";
import useScreenSize from "@/hooks/useScreenSize";
import Scrollbars from "react-custom-scrollbars-2";

const { Paragraph } = Typography;

function GithubQABCard({ props, index }) {
  const { content, download_url, modified, name: title, size, url, language } = props;
  const { middle } = useScreenSize();
  const [copied, setCopied] = useState(false);

  const linkHandler = useCallback((url) => {
    window.open(url, "_blank");
  }, []);

  const copyHandler = useCallback((text) => {
    if (copy(text)) {
      message.success("内容已复制到剪切板");
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    } else {
      message.error("无法复制内容，请确认浏览器支持");
    }
  }, []);

  return (
    <div className={cls("githubCard", { widthStyle: !middle })}>
      <Descriptions
        column={2}
        title={
          <Fragment>
            <Paragraph
              className="title"
              ellipsis={{
                rows: 1,
                expandable: true,
                symbol: "更多",
                onExpand: (e) => e.stopPropagation(),
              }}
              title={`${index}. ${title}`}
              onClick={() => linkHandler(url)}
            >
              {index}. {title}
            </Paragraph>
            <div className="modified">
              <Tooltip title="修改时间">{modified}</Tooltip>
            </div>
          </Fragment>
        }
        extra={
          <Popover
            trigger={"click"}
            arrowPointAtCenter
            placement="topRight"
            content={
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                className="scrollbars"
              >
                <Fragment>
                  <div className="code-toolbar">
                    {copied ? (
                      <Tooltip title="复制成功">
                        <CheckOutlined />
                      </Tooltip>
                    ) : (
                      <Tooltip title="复制">
                        <CopyOutlined
                          role="code-copy"
                          onClick={() => copyHandler(content)}
                        />
                      </Tooltip>
                    )}
                    <Tooltip title="在新页面打开">
                      <EyeOutlined
                        role="code-link"
                        onClick={() => linkHandler(download_url)}
                      />
                    </Tooltip>
                  </div>
                  <SyntaxHighlighter
                    className={"syntaxHighlighter"}
                    children={String(content).replace(/\n$/, "")}
                    style={darcula}
                    language={language}
                    PreTag="div"
                  />
                </Fragment>
              </Scrollbars>
            }
            getPopupContainer={(node) => node.parentNode}
          >
            <Tooltip title="点击查看代码">
              <Button icon={<CodeOutlined />} />
            </Tooltip>
          </Popover>
        }
      />
    </div>
  );
}

export default GithubQABCard;
