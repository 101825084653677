import { useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";

function AuthByWeixin({ open, setOpen }) {
  const [param] = useSearchParams();
  const from = param.get("from") || "";
  // 当前发起登录的模块，用于后端在处理完redirect前端页面时判断应该到哪里去，支持website|console
  const client = "website";
  const redirectUri = encodeURIComponent(
    `https://chat.phitrellis.com/api/system/wechat_login/?from=${from}&client=${client}`
  );
  useLayoutEffect(() => {
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcdf4509ef56e1de4&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect&forcePopup=true`;
  }, []);
}

export default AuthByWeixin;
