import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Avatar, Segmented, Space, Tooltip, message } from "antd";
import { useTimeout } from "ahooks";
import { MODEL_ICONS, MODEL_FEATURES, MODEL_TIPS, MODEL_LIST } from "@/constants/model";

function ModelSegmented() {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { model } = useSelector((state) => state.token);

  const [id, setId] = useState("segmented");

  useTimeout(() => {
    setId();
  }, 3000);

  const changeHandler = useCallback(
    (model) => {
      message.success({
        content: MODEL_TIPS[model],
        style: { marginLeft: 300, marginTop: 42 },
      });
      dispatch.token.update({ model });
    },
    [pathname]
  );

  return (
    <div
      style={{
        width: 900,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Segmented
        className="tour-model"
        id={id}
        onChange={changeHandler}
        value={model}
        options={MODEL_LIST.map((_model) => {
          return {
            label: (
              <Tooltip title={MODEL_FEATURES[_model.value]}>
                <Space size={4}>
                  <Avatar
                    size={24}
                    src={MODEL_ICONS[_model.value]}
                  />
                  <div>{_model.label}</div>
                </Space>
              </Tooltip>
            ),
            value: _model.value,
          };
        })}
      />
    </div>
  );
}

export default ModelSegmented;
