import { useCallback, useState } from "react";
import useScreenSize from "@/hooks/useScreenSize";
import { Button, Tooltip } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import SheetConfigModal from "./SheetConfigModal";

function SheetConfigButton() {
  const { middle } = useScreenSize();
  const [open, setOpen] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [configName, setConfigName] = useState("");
  const clickHandler = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <Tooltip
      placement="top"
      title={"详细配置需要提取的信息"}
      open={openTooltip}
    >
      {middle ? (
        <Button
          shape="round"
          size="small"
          className="input-tools-btn"
          onClick={clickHandler}
          onMouseEnter={() => setOpenTooltip(true)}
          onMouseLeave={() => setOpenTooltip(false)}
        >
          提取配置
          {configName && `: ${configName}`}
        </Button>
      ) : (
        <Button
          size="large"
          type="text"
          icon={<SettingOutlined style={{ color: "var(--brand-1)" }} />}
          onClick={clickHandler}
          onMouseEnter={() => setOpenTooltip(true)}
          onMouseLeave={() => setOpenTooltip(false)}
        ></Button>
      )}
      <SheetConfigModal
        open={open}
        setOpen={setOpen}
        setConfigName={setConfigName}
      />
    </Tooltip>
  );
}

export default SheetConfigButton;
