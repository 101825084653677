import { Modal, notification } from "antd";

export function feedback(message) {
  if (Object.prototype.toString.call(message) === "[object Object]" && message !== null) {
    Object.keys(message).forEach((key) => {
      message[key].forEach((item) => {
        notification.error({ message: key, description: item });
      });
    });
  } else if (Array.isArray(message)) {
    message.forEach((item) => {
      notification.error({ message: "错误", description: item });
    });
  } else {
    notification.error({ message: "错误", description: message });
  }
}

export function prepayment(err) {
  Modal.confirm({
    autoFocusButton: "ok",
    title: "点数/限额不足",
    cancelText: "取消",
    okText: "购买",
    centered: true,
    closable: true,
    mask: true,
    maskClosable: false,
    content:
      `${err}` || "点数不足或此模型额度已用完或套餐无法使用此模型，请续费或升级套餐或使用免费模型(GPT4o mini,文心一言)",
    onCancel: (close) => {
      close();
    },
    onOk: (close) => {
      close();
      window.open("/console/vip", "_blank");
    },
  });
}
