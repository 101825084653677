import { useState, useCallback, forwardRef, useImperativeHandle } from "react";
import { Input, Button } from "antd";
import styles from "./index.module.less";
import ActionIcons from "@/icons/ActionIcons";

const RefineBubble = forwardRef((props, ref) => {
  const { onRefineSend } = props;
  const [refineText, setRefineText] = useState("");
  const [similarText, setSimilarText] = useState("");

  function formatText(refineText, similarText) {
    return "- 降重文本\n" + "```\n" + refineText + "\n```" + "\n- 相似文本\n" + "```\n" + similarText + "\n```"; // eslint-disable-line
  }

  const onOk = useCallback(() => {
    if (validateText()) {
      const text = formatText(refineText, similarText);
      onRefineSend(text);
    }
  }, [refineText, similarText]);

  const validateText = () => {
    if (refineText.trim() === "" || similarText.trim() === "") {
      return false;
    }
    return true;
  };

  return (
    <div className={styles.RefineBubble}>
      <div className="bubble-text refine-text">
        <div className="bubble-text-title">降重文本</div>
        <Input.TextArea
          bordered={false}
          placeholder="请输入降重文本"
          allowClear={true}
          autoSize={{ minRows: 2, maxRows: 4 }}
          onChange={(e) => {
            setRefineText(e.target.value);
          }}
        />
      </div>
      <div className="bubble-text similar-text">
        <div className="bubble-text-title">相似文本</div>
        <Input.TextArea
          bordered={false}
          placeholder="请输入相似文本"
          allowClear={true}
          autoSize={{ minRows: 2, maxRows: 4 }}
          onChange={(e) => {
            setSimilarText(e.target.value);
          }}
        />
      </div>
      <div className="refine-buttons">
        <Button
          type="primary"
          onClick={onOk}
          icon={<ActionIcons type="refine" />}
          className="refine-button"
        >
          开始降重
        </Button>
      </div>
    </div>
  );
});

RefineBubble.displayName = "RefineBubble";
export default RefineBubble;
