import EditorHeader from "./EditorHeader";
import EditorParagraph from "./EditorParagraph";
import styles from "./index.module.less";
import "./style.less";

const EditorContent = ({ outline, dataAllowed, dataOptions, error = [], updateText, addNode, deleteNode }) => {
  return (
    <div className={styles.EditorContent}>
      <EditorHeader
        error={error}
        title={outline.title}
        lang={outline.lang}
        updateText={updateText}
      />
      {(outline.nodes || []).map(({ nodeName, nodeDesc, nodeExpt, nodeData, nodes = [] }, index) => {
        return (
          <EditorParagraph
            error={error}
            deleteDisabled={outline.nodes.length <= 1}
            updateText={updateText}
            addNode={addNode}
            addChildNode={({ level1Index }) => addNode({ level1Index, level2Index: nodes.length - 1 })}
            deleteNode={deleteNode}
            key={index}
            level1={index}
            nodeName={nodeName}
            nodeDesc={nodeDesc}
            nodeExpt={nodeExpt}
            nodeData={nodeData}
            dataAllowed={dataAllowed}
            dataOptions={dataOptions}
            childSize={outline.nodes.length}
            showDesc={nodes.length === 0}
            showExpt={nodes.length === 0}
            showData={nodes.length === 0}
          >
            {nodes.map(
              (
                {
                  nodeName: childNodeName,
                  nodeDesc: childNodeDesc,
                  nodeExpt: childNodeExpt,
                  nodeData: childNodeData,
                  nodes: childNodes,
                },
                childIndex
              ) => {
                return (
                  <EditorParagraph
                    error={error}
                    updateText={updateText}
                    addNode={addNode}
                    addChildNode={({ level1Index, level2Index }) =>
                      addNode({ level1Index, level2Index, level3Index: childNodes.length - 1 })
                    }
                    deleteNode={deleteNode}
                    key={`${index}-${childIndex}`}
                    level1={index}
                    level2={childIndex}
                    nodeName={childNodeName}
                    nodeDesc={childNodeDesc}
                    nodeExpt={childNodeExpt}
                    nodeData={childNodeData}
                    dataAllowed={dataAllowed}
                    dataOptions={dataOptions}
                    childSize={nodes.length}
                    showDesc={childNodes.length === 0}
                    showExpt={childNodes.length === 0}
                    showData={childNodes.length === 0}
                  >
                    {childNodes.map(
                      (
                        {
                          nodeName: grandChildNodeName,
                          nodeDesc: grandChildNodeDesc,
                          nodeExpt: grandChildNodeExpt,
                          nodeData: grandChildNodeData,
                        },
                        grandChildIndex
                      ) => {
                        return (
                          <EditorParagraph
                            error={error}
                            updateText={updateText}
                            addNode={addNode}
                            deleteNode={deleteNode}
                            key={`${index}-${childIndex}-${grandChildIndex}`}
                            level1={index}
                            level2={childIndex}
                            level3={grandChildIndex}
                            nodeName={grandChildNodeName}
                            nodeDesc={grandChildNodeDesc}
                            nodeExpt={grandChildNodeExpt}
                            nodeData={grandChildNodeData}
                            dataAllowed={dataAllowed}
                            dataOptions={dataOptions}
                            childSize={childNodes.length}
                            showDesc={true}
                            showExpt={true}
                            showData={true}
                          ></EditorParagraph>
                        );
                      }
                    )}
                  </EditorParagraph>
                );
              }
            )}
          </EditorParagraph>
        );
      })}
    </div>
  );
};

export default EditorContent;
