import { Select } from "antd";
import { cloneDeep } from "lodash-es";
import cls from "classnames";
import styles from "./index.module.less";

const EditorLangSelector = ({
  label,
  value,
  placeholder,
  onChange,
  required = false,
  dataOptions = [],
  invalid = false,
  defaultValue,
}) => {
  const options = cloneDeep(dataOptions);

  return (
    <div className={styles.langSelectorWrapper}>
      <div className={cls("editorLangSelector", { invalid })}>
        {required ? <i className="required">*</i> : null}
        <span className={cls("label")}>{label}</span>
        <Select
          // mode="SECRET_COMBOBOX_MODE_DO_NOT_USE"
          allowClear={true}
          showSearch={true}
          bordered={false}
          placeholder={placeholder}
          onChange={(text) => onChange(text)}
          options={options}
          value={value}
          defaultValue={defaultValue}
        />
      </div>
    </div>
  );
};

export default EditorLangSelector;
