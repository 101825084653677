import Joyride from "react-joyride";
import { useDispatch } from "react-redux";

const Tour = (props) => {
  const dispatch = useDispatch();
  const { run, steps } = props;

  const handleJoyrideCallback = (data) => {
    const { action } = data;
    // tab-label步骤控制页面滚动位置,根据步骤切换tab
    if ((action === "next" || action === "prev") && data.step.target.startsWith(".tour-tab")) {
      const str = data.step.target.replace(".", "");
      dispatch.chat.update({
        activeTabKey: `question-type-card-${str.charAt(str.length - 1)}`,
      });
    }
    if (action === "reset") {
      dispatch.user.update({ userGuide: false });
    }
  };

  return (
    <>
      <Joyride
        steps={steps}
        continuous={true}
        locale={{ back: "上一步", close: "关闭", last: "结束", next: "下一步", skip: "跳过" }}
        styles={{
          options: {
            primaryColor: "#60A5FA",
            textColor: "#000",
          },
          tooltipContainer: {
            textAlign: "left",
            fontSize: "14px",
          },
          buttonNext: {
            padding: "4px",
            fontSize: "14px",
            outline: "none",
          },
          buttonBack: {
            padding: "4px",
            fontSize: "14px",
            outline: "none",
          },
        }}
        showSkipButton={true}
        disableOverlayClose={true}
        run={run}
        callback={handleJoyrideCallback}
      />
    </>
  );
};

export default Tour;
