import styles from "./index.module.less";
import { Fragment, useState, useEffect, useCallback } from "react";
import ScrollToIcons from "@/icons/ScrollToIcons";

const ScrollToBottom = ({ sessionId }) => {
  const [showButton, setShowButton] = useState(false);
  const ptarget = document.querySelector(".message-scrollbar");
  const target = ptarget?.querySelector("div:first-child");

  const scrollHandler = (e) => {
    if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  };

  useEffect(() => {
    target.addEventListener("scroll", scrollHandler);

    return () => {
      target.removeEventListener("scroll", scrollHandler);
      setShowButton(false);
    };
  }, []);

  const toBottomHandler = useCallback(() => {
    target.scrollTo({ behavior: "smooth", top: target.scrollHeight });
  });

  return (
    <Fragment>
      {showButton ? (
        <div
          className={styles.ScrollToBottom}
          onClick={toBottomHandler}
        >
          <div className="scroll-to-bottom-content">
            <ScrollToIcons type="down" />
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default ScrollToBottom;
