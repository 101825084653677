import { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import ThesisComposerPlaceholder from "./ThesisComposerPlaceholder";

function PlaceholderBubble({ type }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch.chat.update({
      pluginPlaceHolder: true,
    });
    return () => {
      dispatch.chat.update({
        pluginPlaceHolder: false,
      });
    };
  }, []);

  switch (type) {
    // case "论文撰写":
    // case "文献综述":
    // case "文章重组":
    // case "开题报告":
    // case "长文撰写":
    //   return <ThesisComposerPlaceholder />;
    default:
      return "";
  }
}

export default memo(PlaceholderBubble);
