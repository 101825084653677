import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import { message } from "antd";
import { data as dataService } from "@/services";
import styles from "./index.module.less";
import { last } from "lodash-es";
import { feedback } from "@/utils/index";

function TotalLengthErrorBubble({ error }) {
  const dispatch = useDispatch();
  const match = useMatch("/chat");
  const { sessionId: chatId } = useSelector((state) => state.chat);
  const { sessionId: docId, sessionType, paths } = useSelector((state) => state.document);

  const clearHandler = useCallback(async () => {
    try {
      const res = await dataService.clearSession(match ? chatId : sessionType === 2 ? last(paths) : docId);
      if (res.status === "OK") {
        message.success("当前会话已清空");
        dispatch[match ? "chat" : "document"].version();
      } else feedback(res.data || "清空失败，请重试");
    } catch (err) {
      if (err.code !== 404) {
        feedback(err.data || "清空失败，请重试");
      }
    }
  }, [match, chatId, docId]);

  return (
    <div className={styles.bubbleLeftWrapper}>
      <div className="bubble-container">
        <div>
          <div className="error-info">
            {error}。或<span onClick={clearHandler}>清空</span>当前会话
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(TotalLengthErrorBubble);
