export const PDF = (
  <svg
    t="1718778394938"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="12351"
    width="24"
    height="24"
  >
    <path
      d="M535.198324 0h69.53853v95.276816c124.175945 0.677323 248.351891-1.354647 373.205159 0.677324a40.6394 40.6394 0 0 1 45.154889 45.154889c1.919083 234.579649 0 469.272186 1.128872 703.851835-1.128872 24.044979 2.257744 50.686363-11.288722 72.022049-17.045971 12.417595-38.946092 11.288722-58.814243 11.288722H604.962628v95.276816h-72.925146C354.917429 990.924044 177.458715 960.557381 0 928.723184V95.502591C178.361812 63.555507 356.836512 32.51152 535.198324 0z"
      fill="#A33639"
      p-id="12352"
    ></path>
    <path
      d="M604.736854 130.949179h383.816558v762.101642h-383.816558v-95.276816h302.42487v-47.638408H604.736854v-59.491567h302.42487V643.457171H604.736854v-60.056002h302.42487v-47.638409H604.736854v-59.491566h302.42487V428.971447H604.736854v-59.830228h302.42487v-47.638408H604.736854V261.898357h302.42487v597.17341H604.736854z"
      fill="#FFFFFF"
      p-id="12353"
    ></path>
    <path
      d="M645.489141 529.66685h302.424871v47.638409H645.489141zM645.489141 611.510087h302.424871v47.638408H645.489141zM645.489141 693.353324h302.424871v47.638408H645.489141zM596.383199 775.19656h351.530813v47.638409H596.383199z"
      fill="#A33639"
      p-id="12354"
    ></path>
    <path
      d="M180.619557 317.325984c59.265792 2.822181 130.949179-24.044979 180.619557 21.335685 46.961085 58.475582 34.54349 165.831331-35.107927 200.713483-24.722302 12.982031-53.169882 11.288722-80.037041 10.272737v130.949179L180.619557 675.065594c-0.903098-119.208908-1.128872-238.530702 0-357.73961z"
      fill="#FFFFFF"
      p-id="12355"
    ></path>
    <path
      d="M245.642597 377.720648c21.448572-1.015985 48.089957-5.079925 62.539522 15.578437a82.407673 82.407673 0 0 1 1.467534 73.263808c-12.417595 22.577445-40.526513 20.771249-62.313747 23.367655-2.257744-37.365671-2.03197-74.731342-1.693309-112.2099zM885.713152 379.865505a83.988094 83.988094 0 0 1-48.315732-19.642376 460.57987 460.57987 0 0 0-77.666409 24.835189c-20.3197 36.011024-39.171866 54.411641-55.540514 54.411641a17.384632 17.384632 0 0 1-9.821188-2.596406 19.416602 19.416602 0 0 1-11.288723-17.723294c0-5.870136 1.354647-22.577445 63.329733-49.105942a467.917539 467.917539 0 0 0 34.656377-81.278801c-7.902106-15.691324-24.835189-54.298754-13.094918-73.941131a20.658362 20.658362 0 0 1 20.206813-10.498511 21.900121 21.900121 0 0 1 17.045971 8.466541c8.466542 11.288722 7.789218 36.688347-3.27373 73.376695A196.988204 196.988204 0 0 0 842.70312 338.661669a221.14607 221.14607 0 0 1 41.090949-4.515489c30.705325 0.677323 35.333701 15.014001 34.656377 23.59343 0 22.577445-21.56146 22.577445-32.624407 22.577444z m-185.022159 42.106935l2.257745-0.564436a45.154889 45.154889 0 0 0 23.932091-16.820197 56.443612 56.443612 0 0 0-26.189836 17.384633z m93.357734-200.261934h-2.144858a4.402602 4.402602 0 0 0-2.82218 0.677323 52.492559 52.492559 0 0 0 4.176827 33.866167 53.282769 53.282769 0 0 0 0.790211-34.54349zM790.210561 317.551758v1.24176l-0.677323-0.677324c-5.418587 14.336677-11.288722 28.44758-18.061956 42.332709l1.128872-0.677323V361.239114A346.338 346.338 0 0 1 812.788006 348.595745l-0.677324-0.564436h1.806196a196.310881 196.310881 0 0 1-23.706317-30.479551z m94.599493 34.769265a66.151913 66.151913 0 0 0-18.174843 1.693308 50.686363 50.686363 0 0 0 20.997024 6.660347 26.076949 26.076949 0 0 0 13.885128-1.580422c-0.451549-2.596406-3.273729-6.773233-17.158858-6.773233z"
      fill="#A33639"
      p-id="12356"
    ></path>
  </svg>
);

export const DOC = (
  <svg
    t="1718779532438"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="16222"
    width="24"
    height="24"
  >
    <path
      d="M535.119473 0h69.599248v95.247413C729.226717 96.331138 853.614299 93.92286 977.881468 96.331138a40.459078 40.459078 0 0 1 44.914393 45.516463c2.047037 234.566322 0 469.614299 1.204139 703.819379-1.204139 24.082785 2.287865 50.694262-11.318909 72.248354-16.978363 12.041392-38.893697 10.837253-58.761994 12.041392h-349.200376V1023.518344h-72.248354C354.980245 990.886171 177.490122 960.541863 0 928.752587V95.488241C178.33302 63.578551 356.786453 32.511759 535.119473 0z"
      fill="#2A5699"
      p-id="16223"
    ></path>
    <path
      d="M604.718721 131.010348H988.598307v761.979304H604.718721v-95.247413h302.479774v-48.165569H604.718721v-59.002822h302.479774v-48.16557H604.718721v-59.002822h302.479774v-48.165569H604.718721v-60.206961h302.479774V428.673565H604.718721v-60.206961h302.479774v-46.96143H604.718721v-59.604892h302.479774V214.336783H604.718721zM240.827846 341.373471c22.156162-1.324553 44.19191-2.287865 66.348071-3.492003 15.533396 80.4365 31.30762 160.632173 48.165569 240.827845 13.125118-82.724365 27.695202-165.087488 41.783632-247.571025 23.239887-0.842897 46.479774-2.167451 69.719661-3.612418-26.370649 115.356538-49.369708 231.796802-78.148636 346.430856-19.386642 10.355597-48.165569 0-71.52587 1.204139C301.034807 596.169332 283.093133 517.779868 269.245532 438.667921c-13.606773 76.944497-31.30762 153.16651-46.841016 229.508937-22.39699-1.204139-44.793979-2.528692-67.311383-4.094073-19.266228-104.760113-42.024459-208.918156-60.206962-313.919097 19.868297-0.963311 39.857008-1.806209 60.206962-2.528693 12.041392 75.860771 25.648166 151.360301 36.124177 227.341487 16.135466-77.907808 32.873001-155.695202 49.610536-233.603011z"
      fill="#FFFFFF"
      p-id="16224"
    ></path>
  </svg>
);

export const DOCX = (
  <svg
    t="1718779532438"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="16222"
    width="24"
    height="24"
  >
    <path
      d="M535.119473 0h69.599248v95.247413C729.226717 96.331138 853.614299 93.92286 977.881468 96.331138a40.459078 40.459078 0 0 1 44.914393 45.516463c2.047037 234.566322 0 469.614299 1.204139 703.819379-1.204139 24.082785 2.287865 50.694262-11.318909 72.248354-16.978363 12.041392-38.893697 10.837253-58.761994 12.041392h-349.200376V1023.518344h-72.248354C354.980245 990.886171 177.490122 960.541863 0 928.752587V95.488241C178.33302 63.578551 356.786453 32.511759 535.119473 0z"
      fill="#2A5699"
      p-id="16223"
    ></path>
    <path
      d="M604.718721 131.010348H988.598307v761.979304H604.718721v-95.247413h302.479774v-48.165569H604.718721v-59.002822h302.479774v-48.16557H604.718721v-59.002822h302.479774v-48.165569H604.718721v-60.206961h302.479774V428.673565H604.718721v-60.206961h302.479774v-46.96143H604.718721v-59.604892h302.479774V214.336783H604.718721zM240.827846 341.373471c22.156162-1.324553 44.19191-2.287865 66.348071-3.492003 15.533396 80.4365 31.30762 160.632173 48.165569 240.827845 13.125118-82.724365 27.695202-165.087488 41.783632-247.571025 23.239887-0.842897 46.479774-2.167451 69.719661-3.612418-26.370649 115.356538-49.369708 231.796802-78.148636 346.430856-19.386642 10.355597-48.165569 0-71.52587 1.204139C301.034807 596.169332 283.093133 517.779868 269.245532 438.667921c-13.606773 76.944497-31.30762 153.16651-46.841016 229.508937-22.39699-1.204139-44.793979-2.528692-67.311383-4.094073-19.266228-104.760113-42.024459-208.918156-60.206962-313.919097 19.868297-0.963311 39.857008-1.806209 60.206962-2.528693 12.041392 75.860771 25.648166 151.360301 36.124177 227.341487 16.135466-77.907808 32.873001-155.695202 49.610536-233.603011z"
      fill="#FFFFFF"
      p-id="16224"
    ></path>
  </svg>
);

export const PPT = (
  <svg
    t="1718779807780"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="17368"
    width="24"
    height="24"
  >
    <path
      d="M538.731891 0h65.98683v107.168391c124.387582 0.722484 248.895579-1.324553 373.28316 0a40.699906 40.699906 0 0 1 45.034808 46.118533c2.047037 222.404516 0 444.929445 1.204139 667.454374-1.204139 24.082785 2.287865 50.694262-11.198495 72.248354-16.978363 12.041392-39.014111 10.957667-59.002822 12.041392-116.319849-0.60207-232.639699 0-349.200376 0V1023.518344h-72.248354C355.100659 990.886171 177.490122 960.662277 0 928.752587V95.488241C179.537159 63.698965 359.074318 31.30762 538.731891 0z"
      fill="#D24625"
      p-id="17369"
    ></path>
    <path
      d="M604.718721 142.931326H988.598307v726.216369H604.718721v-95.247413h279.239887v-47.563499H604.718721v-60.206962h279.239887v-46.96143H604.839135v-69.960489c46.118532 14.570085 98.619003 14.208843 139.800564-14.088429 44.553151-27.093133 67.793039-78.630292 71.646284-130.047036H663.119473c0-51.777987 0.60207-103.555974-0.963311-155.213547-19.145814 3.732832-38.171214 7.826905-57.196614 12.041392z"
      fill="#FFFFFF"
      p-id="17370"
    ></path>
    <path
      d="M686.35936 224.69238a165.689558 165.689558 0 0 1 153.16651 156.5381c-51.055503 0.60207-102.111007 0-153.286924 0 0.120414-52.380056 0.120414-104.278457 0.120414-156.5381z"
      fill="#D24625"
      p-id="17371"
    ></path>
    <path
      d="M186.64158 314.521167c63.21731 3.130762 139.680151-25.527752 192.662277 22.878645 50.092192 62.374412 36.84666 176.888053-37.44873 214.095955-26.370649 13.847601-56.714958 12.041392-85.373471 10.957667v139.68015l-69.238006-5.900282c-1.806209-127.157103-2.047037-254.434619-0.60207-381.712135z"
      fill="#FFFFFF"
      p-id="17372"
    ></path>
    <path
      d="M255.759172 378.942615c22.878645-0.963311 51.296331-5.298213 66.709313 16.737536a87.902164 87.902164 0 0 1 1.565381 78.148635c-13.245532 24.082785-43.228598 22.035748-66.468485 24.925682-2.408278-39.857008-2.167451-79.714017-1.806209-119.811853z"
      fill="#D24625"
      p-id="17373"
    ></path>
  </svg>
);

export const PPTX = (
  <svg
    t="1718779807780"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="17368"
    width="24"
    height="24"
  >
    <path
      d="M538.731891 0h65.98683v107.168391c124.387582 0.722484 248.895579-1.324553 373.28316 0a40.699906 40.699906 0 0 1 45.034808 46.118533c2.047037 222.404516 0 444.929445 1.204139 667.454374-1.204139 24.082785 2.287865 50.694262-11.198495 72.248354-16.978363 12.041392-39.014111 10.957667-59.002822 12.041392-116.319849-0.60207-232.639699 0-349.200376 0V1023.518344h-72.248354C355.100659 990.886171 177.490122 960.662277 0 928.752587V95.488241C179.537159 63.698965 359.074318 31.30762 538.731891 0z"
      fill="#D24625"
      p-id="17369"
    ></path>
    <path
      d="M604.718721 142.931326H988.598307v726.216369H604.718721v-95.247413h279.239887v-47.563499H604.718721v-60.206962h279.239887v-46.96143H604.839135v-69.960489c46.118532 14.570085 98.619003 14.208843 139.800564-14.088429 44.553151-27.093133 67.793039-78.630292 71.646284-130.047036H663.119473c0-51.777987 0.60207-103.555974-0.963311-155.213547-19.145814 3.732832-38.171214 7.826905-57.196614 12.041392z"
      fill="#FFFFFF"
      p-id="17370"
    ></path>
    <path
      d="M686.35936 224.69238a165.689558 165.689558 0 0 1 153.16651 156.5381c-51.055503 0.60207-102.111007 0-153.286924 0 0.120414-52.380056 0.120414-104.278457 0.120414-156.5381z"
      fill="#D24625"
      p-id="17371"
    ></path>
    <path
      d="M186.64158 314.521167c63.21731 3.130762 139.680151-25.527752 192.662277 22.878645 50.092192 62.374412 36.84666 176.888053-37.44873 214.095955-26.370649 13.847601-56.714958 12.041392-85.373471 10.957667v139.68015l-69.238006-5.900282c-1.806209-127.157103-2.047037-254.434619-0.60207-381.712135z"
      fill="#FFFFFF"
      p-id="17372"
    ></path>
    <path
      d="M255.759172 378.942615c22.878645-0.963311 51.296331-5.298213 66.709313 16.737536a87.902164 87.902164 0 0 1 1.565381 78.148635c-13.245532 24.082785-43.228598 22.035748-66.468485 24.925682-2.408278-39.857008-2.167451-79.714017-1.806209-119.811853z"
      fill="#D24625"
      p-id="17373"
    ></path>
  </svg>
);

export const XLS = (
  <svg
    t="1718780146519"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="21079"
    width="24"
    height="24"
  >
    <path
      d="M943.5 179.7H643.2v55.6h88.5v87.9h-88.5v28h88.5v88h-88.5V468h88.5v83.2h-88.5v33.4h88.5V668h-88.5v33.4h88.5v83.9h-88.5v61.2h300.3c4.7-1.4 8.7-7 11.9-16.7 3.2-9.8 4.8-17.7 4.8-23.8V189.8c0-4.8-1.6-7.7-4.8-8.7-3.9-1-7.9-1.5-11.9-1.4z m-39 605.5h-144v-83.8h144.1l-0.1 83.8z m0-117.2h-144v-83.5h144.1l-0.1 83.5z m0-116.8h-144v-82.9h144.1l-0.1 82.9z m0-112h-144v-87.9h144.1l-0.1 87.9z m0-116.5h-144v-87.4h144.1v88l-0.1-0.6zM63.8 165.8v694.7L592.7 952V72L63.8 166.1v-0.3z m313.5 525.5c-2-5.5-11.5-28.6-28.3-69.6-9.7-23.9-19.7-47.8-29.8-71.6h-0.9l-56.7 135-75.8-5.1 89.8-168-82.4-168 77.3-4.1 51.1 131.5h1l57.7-137.5 79.9-5-95.1 181.9 98 185.5-85.8-5z"
      fill="#107B0F"
      p-id="21080"
    ></path>
  </svg>
);

export const XLSX = (
  <svg
    t="1718780146519"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="21079"
    width="24"
    height="24"
  >
    <path
      d="M943.5 179.7H643.2v55.6h88.5v87.9h-88.5v28h88.5v88h-88.5V468h88.5v83.2h-88.5v33.4h88.5V668h-88.5v33.4h88.5v83.9h-88.5v61.2h300.3c4.7-1.4 8.7-7 11.9-16.7 3.2-9.8 4.8-17.7 4.8-23.8V189.8c0-4.8-1.6-7.7-4.8-8.7-3.9-1-7.9-1.5-11.9-1.4z m-39 605.5h-144v-83.8h144.1l-0.1 83.8z m0-117.2h-144v-83.5h144.1l-0.1 83.5z m0-116.8h-144v-82.9h144.1l-0.1 82.9z m0-112h-144v-87.9h144.1l-0.1 87.9z m0-116.5h-144v-87.4h144.1v88l-0.1-0.6zM63.8 165.8v694.7L592.7 952V72L63.8 166.1v-0.3z m313.5 525.5c-2-5.5-11.5-28.6-28.3-69.6-9.7-23.9-19.7-47.8-29.8-71.6h-0.9l-56.7 135-75.8-5.1 89.8-168-82.4-168 77.3-4.1 51.1 131.5h1l57.7-137.5 79.9-5-95.1 181.9 98 185.5-85.8-5z"
      fill="#107B0F"
      p-id="21080"
    ></path>
  </svg>
);

export const CSV = (
  <svg
    t="1718780808751"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="61102"
    width="24"
    height="24"
  >
    <path
      d="M1024 140.8c0-12.8-3.2-25.6-12.8-32-9.6-9.6-22.4-12.8-32-12.8-124.8-3.2-249.6 0-374.4 0V0h-70.4C355.2 32 179.2 64 0 96v832c176 32 355.2 60.8 531.2 96h73.6v-96h348.8c19.2 0 41.6 0 57.6-12.8 12.8-22.4 9.6-48 12.8-73.6V140.8zM467.2 572.8c-6.4 41.6-28.8 80-60.8 108.8-32 25.6-70.4 38.4-108.8 35.2-64 0-115.2-22.4-147.2-64-28.8-35.2-41.6-83.2-41.6-140.8 0-57.6 16-105.6 44.8-140.8 32-41.6 80-64 144-64 41.6-3.2 83.2 12.8 115.2 35.2 28.8 25.6 48 57.6 54.4 96l3.2 19.2h-89.6l-3.2-12.8c-3.2-16-12.8-32-25.6-41.6-16-9.6-35.2-12.8-51.2-12.8-32 0-54.4 12.8-70.4 32-16 19.2-22.4 48-22.4 86.4 0 38.4 6.4 67.2 22.4 86.4 16 19.2 38.4 28.8 73.6 28.8 19.2 0 38.4-3.2 51.2-16 16-12.8 25.6-32 28.8-51.2l3.2-12.8h89.6l-9.6 28.8z m435.2 211.2h-278.4v-544h278.4v544z"
      fill="#107B0F"
      p-id="61103"
    ></path>
    <path
      d="M684.8 320h156.8v67.2h-156.8zM684.8 454.4h156.8v67.2h-156.8zM684.8 585.6h156.8v67.2h-156.8z"
      fill="#107B0F"
      p-id="61104"
    ></path>
  </svg>
);

export const TXT = (
  <svg
    t="1718781063698"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="66024"
    width="24"
    height="24"
  >
    <path
      d="M535.94 71.69h57.58v91.4c108.56 0.61 217.22-1.12 325.68 0.51 23.36-2.23 41.64 15.95 39.3 39.3 1.73 189.71-0.41 379.52 1 569.33-1 20.51 2 43.26-9.75 61.44-14.83 10.76-34.12 9.34-51.48 10.16-101.56-0.51-203.11-0.31-304.77-0.31v101.55h-63.15C375.59 916.83 220.5 891 65.63 863.82q-0.15-355.4 0-710.69C222.34 126 379 98.39 535.94 71.69z"
      fill="#1296db"
      p-id="66025"
      data-spm-anchor-id="a313x.search_index.0.i34.41f53a81bW0lDI"
    ></path>
    <path
      d="M138.52 547.51V444.39h-34.1v-21h91.31v21h-34v103.12zM199 547.51l39.27-64.77-35.58-59.35h27.12l23 39.88 22.57-39.88h26.89l-35.74 60.28 39.27 63.84h-28l-25.4-42.92-25.55 42.92zM343.63 547.51V444.39h-34.1v-21h91.31v21h-34v103.12zM590.14 193.55v302.14h126.94v12.69H590.14v88.86h265.74v38.08H590.14v48.24h265.74v38.08H590.14V813h335.14V193.55z m198.11 250c-4.53 6.7-10.62 17.86-12.12 31.13a8.11 8.11 0 0 1-5.17 6.88l-43.83 15.86-4.93-2.57 20.22-27.09a15.45 15.45 0 0 0 5 0.79c7.84-0.08 14.14-5.95 14.07-13.09s-6.49-12.89-14.33-12.81-14.14 5.94-14.06 13.09a11.89 11.89 0 0 0 1 4.63 8.05 8.05 0 0 1-0.55 8.14l-17.15 23-4.09-3.82-0.13-42.48a8.25 8.25 0 0 1 4.66-7.51c6.93-3.32 20.19-10.74 30.28-22.81a8.22 8.22 0 0 1 10.81-1.46l28 18.43a8.35 8.35 0 0 1 2.32 11.72z m19.94-14.67a8.35 8.35 0 0 1-11 2l-42.74-26.63a8.35 8.35 0 0 1-2.52-11.73l0.32-0.47c2.53-3.77 4.47-1.64 8.3 0.8L802.93 420c4.13 2.61 8.25 5 5.25 8.91z m36.18-53.69l-24.11 35.7a13 13 0 0 1-18 3.48l-28.55-19.27a13 13 0 0 1-3.48-18l24.11-35.69a12.94 12.94 0 0 1 18-3.48l28.56 19.29a13 13 0 0 1 3.46 18z"
      fill="#FFFFFF"
      p-id="66026"
    ></path>
  </svg>
);

export const NORMAL = (
  <svg
    t="1718781338129"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="72846"
    width="24"
    height="24"
  >
    <path
      d="M578.33887 289.169435V61.23588c0-34.019934-27.215947-61.23588-61.23588-61.23588-57.833887 0-115.667774 6.803987-170.099668 23.813953L71.44186 108.863787C30.61794 122.471761 0 163.295681 0 207.521595v602.152824c0 40.82392 27.215947 81.647841 64.637874 95.255814l227.933555 102.0598c27.215947 10.20598 57.833887 17.009967 88.451827 17.009967 34.019934 0 61.23588-27.215947 61.23588-61.23588V391.229236c0-10.20598 6.803987-20.41196 17.009967-23.813954l78.245847-20.41196c23.813953-10.20598 40.82392-30.61794 40.82392-57.833887z"
      fill="#1296db"
      p-id="72847"
    ></path>
    <path
      d="M1020.598007 350.405316c0-3.401993-3.401993-3.401993-3.401994-6.803987l-272.159468-272.159469h-3.401994s-3.401993 0-3.401993-3.401993h-3.401993-3.401994l-102.0598 20.41196c-6.803987 0-13.607973 10.20598-13.607974 17.009967v183.707641c0 40.82392-27.215947 74.843854-64.637873 88.451827-3.401993 0-61.23588 17.009967-61.235881 17.009967-6.803987 3.401993-13.607973 10.20598-13.607973 17.009967v489.887043c0 10.20598 6.803987 17.009967 17.009967 17.009967h510.299003c10.20598 0 17.009967-6.803987 17.009967-17.009967v-544.318937-6.803986z m-272.159469-224.531562l214.325582 214.325582H748.438538V125.873754zM901.528239 748.438538h-340.199335c-10.20598 0-17.009967-6.803987-17.009967-17.009967s6.803987-17.009967 17.009967-17.009966h340.199335c10.20598 0 17.009967 6.803987 17.009967 17.009966s-6.803987 17.009967-17.009967 17.009967z m0-102.0598h-340.199335c-10.20598 0-17.009967-6.803987-17.009967-17.009967s6.803987-17.009967 17.009967-17.009967h340.199335c10.20598 0 17.009967 6.803987 17.009967 17.009967s-6.803987 17.009967-17.009967 17.009967z m0-102.059801h-340.199335c-10.20598 0-17.009967-6.803987-17.009967-17.009967s6.803987-17.009967 17.009967-17.009967h340.199335c10.20598 0 17.009967 6.803987 17.009967 17.009967s-6.803987 17.009967-17.009967 17.009967z"
      fill="#1296db"
      p-id="72848"
    ></path>
  </svg>
);

export const Folder = (
  <svg
    t="1725522754470"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="5189"
    width="24"
    height="24"
  >
    <path
      d="M855.04 385.024q19.456 2.048 38.912 10.24t33.792 23.04 21.504 37.376 2.048 54.272q-2.048 8.192-8.192 40.448t-14.336 74.24-18.432 86.528-19.456 76.288q-5.12 18.432-14.848 37.888t-25.088 35.328-36.864 26.112-51.2 10.24l-567.296 0q-21.504 0-44.544-9.216t-42.496-26.112-31.744-40.96-12.288-53.76l0-439.296q0-62.464 33.792-97.792t95.232-35.328l503.808 0q22.528 0 46.592 8.704t43.52 24.064 31.744 35.84 12.288 44.032l0 11.264-53.248 0q-40.96 0-95.744-0.512t-116.736-0.512-115.712-0.512-92.672-0.512l-47.104 0q-26.624 0-41.472 16.896t-23.04 44.544q-8.192 29.696-18.432 62.976t-18.432 61.952q-10.24 33.792-20.48 65.536-2.048 8.192-2.048 13.312 0 17.408 11.776 29.184t29.184 11.776q31.744 0 43.008-39.936l54.272-198.656q133.12 1.024 243.712 1.024l286.72 0z"
      p-id="5190"
      fill="#F2BB05"
    ></path>
  </svg>
);

export const EPUB = (
  <svg
    t="1725617201966"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="5520"
    width="24"
    height="24"
  >
    <path
      d="M422.542 663.196a28.307 28.307 0 0 0 13.482-10.48 28.754 28.754 0 0 0 4.889-16.613 27.604 27.604 0 0 0-6.837-19.17 29.84 29.84 0 0 0-17.349-9.584 207.383 207.383 0 0 0-31.023-1.47h-20.99v60.704h23.61q25.496-0.064 34.218-3.387z m416.364-9.201a28.499 28.499 0 0 0 17.412-7.636 22.556 22.556 0 0 0 6.39-16.678 23.419 23.419 0 0 0-5.463-15.974 24.729 24.729 0 0 0-16.23-7.508q-6.39-0.735-36.87-0.735h-24.761v49.393h28.275q25.112-0.127 31.247-0.862z"
      fill="#FF7447"
      p-id="5521"
    ></path>
    <path
      d="M938.172 482.459h-26.358V220.474a9.585 9.585 0 0 0-0.575-3.195 9.585 9.585 0 0 0-2.237-10L704.527 2.803a9.585 9.585 0 0 0-16.358 6.806v204.475a9.585 9.585 0 0 0 9.584 9.585h194.891v258.79H145.03V20.79h500.007a9.585 9.585 0 0 0 0-19.17H135.445a9.585 9.585 0 0 0-9.585 9.585V482.46H99.502a27.956 27.956 0 0 0-27.956 27.955v327.481a27.956 27.956 0 0 0 27.956 27.956h26.358v52.173A106.072 106.072 0 0 0 231.836 1024H902.23a9.585 9.585 0 0 0 9.585-9.585V865.851h26.358a27.956 27.956 0 0 0 27.956-27.956v-327.48a27.956 27.956 0 0 0-27.956-27.956z m-230.834-277.96V32.74l171.76 171.76zM520.307 569.81h43.131v115.656a239.62 239.62 0 0 0 1.598 35.687 34.57 34.57 0 0 0 13.195 21.055q10.415 7.955 28.467 7.955t27.668-7.508a29.649 29.649 0 0 0 11.214-18.435 240.419 240.419 0 0 0 1.885-36.262V569.744h43.132v112.174q0 38.34-3.483 54.314a60.129 60.129 0 0 1-12.78 26.806 65.145 65.145 0 0 1-25.144 17.412q-15.719 6.39-41.086 6.39-30.608 0-46.39-7.06a66.327 66.327 0 0 1-24.985-18.372 58.819 58.819 0 0 1-12.077-23.674q-4.217-18.37-4.217-54.314z m-198.693 0h69.202q39.33 0 51.279 3.194a56.007 56.007 0 0 1 30.735 20.895q12.396 15.975 12.396 41.534a69.681 69.681 0 0 1-7.124 33.068 58.595 58.595 0 0 1-18.147 21.054 62.493 62.493 0 0 1-22.365 10.128 246.01 246.01 0 0 1-44.73 3.195h-28.114v80.545h-43.164zM122.602 783.357v-213.55h158.34v36.135H165.733v47.349h107.222v35.975H165.733v58.116H285.16v35.975zM892.58 1004.83H231.836a86.902 86.902 0 0 1-86.806-86.806v-52.173h747.614z m15.656-255.051a57.093 57.093 0 0 1-18.499 22.013 61.12 61.12 0 0 1-28.914 10.128q-10.767 1.182-52.014 1.47h-72.62V569.808h85.368a246.745 246.745 0 0 1 37.796 2.109 55.56 55.56 0 0 1 22.365 8.818 54.825 54.825 0 0 1 16.39 17.86 48.34 48.34 0 0 1 6.55 24.984 50.64 50.64 0 0 1-30.001 46.327 56.23 56.23 0 0 1 30 19.36 51.438 51.438 0 0 1 10.48 32.206 63.483 63.483 0 0 1-6.965 28.307z"
      fill="#FF7447"
      p-id="5522"
    ></path>
    <path
      d="M852.388 693.324q-8.818-3.195-38.339-3.195h-34.825v57.094h39.905a195.083 195.083 0 0 0 29.585-1.31 26.742 26.742 0 0 0 15.655-8.53 26.23 26.23 0 0 0 6.039-18.148 27.86 27.86 0 0 0-4.665-16.326 26.358 26.358 0 0 0-13.355-9.585z"
      fill="#FF7447"
      p-id="5523"
    ></path>
  </svg>
);
