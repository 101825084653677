/**
 * eventsource消息
 */
export const eventsource = {
  state: {
    messageMap: {},
    uploadMap: {},
    paymentMap: {},
  },
  reducers: {
    updateMessage(state, payload) {
      if (!payload.messageId) {
        return state;
      }
      return {
        ...state,
        messageMap: {
          ...state.messageMap,
          [payload.messageId]: {
            ...payload,
          },
        },
      };
    },
    updateUpload(state, payload) {
      if (!payload.id) {
        return state;
      }
      return {
        ...state,
        uploadMap: {
          ...state.uploadMap,
          [payload.id]: {
            ...payload,
          },
        },
      };
    },
    updatePayment(state, payload) {
      if (!payload.id) {
        return state;
      }
      return {
        ...state,
        paymentMap: {
          ...state.paymentMap,
          [payload.id]: {
            ...payload,
          },
        },
      };
    },
  },
};
