import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Space } from "antd";
import cls from "classnames";
import WaveSurfer from "wavesurfer.js";
import { useFullscreen, useToggle, useDebounceEffect } from "ahooks";
import useScreenSize from "@/hooks/useScreenSize";
import BubbleToolbar from "../BubbleToolbar";
import { setBubbleBgColor, setBubbleSelectable } from "@/utils/index";
import styles from "./index.module.less";

function MusicBubble({ text, createdAt, finished, setComposerText, messageId, position, isLast, questionType }) {
  const containRef = useRef();
  const { middle } = useScreenSize();
  const dispatch = useDispatch();
  const [isFullscreen, { toggleFullscreen, enterFullscreen, exitFullscreen }] = useFullscreen(
    document.querySelector(`.bubble-${position}-${messageId}`),
    {
      pageFullscreen: true,
    }
  );
  const { checking, checkedList } = useSelector((state) => state.user);
  const [isMarkdownFormat, { toggle: markdownFormatToggle }] = useToggle(true);
  const [touchTimes, setTouchTimes] = useState(0);

  useEffect(() => {
    const el = document.querySelector(`.audio-${messageId}`);
    if (!el) return;
    const wavesurfer = WaveSurfer.create({
      container: el,
      url: text,
      height: 128,
      splitChannels: false,
      normalize: true,
      waveColor: "#ff4e00",
      progressColor: "#dd5e98",
      cursorColor: "#ddd5e9",
      cursorWidth: 2,
      barWidth: 2,
      barGap: 2,
      barRadius: NaN,
      barHeight: 0.5,
      barAlign: "",
      minPxPerSec: 1,
      fillParent: true,
      mediaControls: true,
      autoplay: false,
      interact: true,
      hideScrollbar: false,
      audioRate: 1,
      autoScroll: true,
      autoCenter: true,
      sampleRate: 8000,
    });
    wavesurfer.on("ready", () => {
      wavesurfer.play();
    });
  }, []);

  const clickHandler = useCallback(() => {
    if (checking) {
      const isChecked = checkedList.includes(messageId);
      const checkable = setBubbleBgColor(messageId, !isChecked);
      if (!checkable) return;
      setBubbleSelectable(false);
      if (isChecked) {
        dispatch.user.update({
          checkedList: checkedList.filter((item) => item !== messageId),
        });
      } else dispatch.user.update({ checkedList: [...checkedList, messageId] });
    }
  }, [checkedList]);

  const bubbleClickHandler = useCallback(
    (e) => {
      const mask = document.querySelector(".ant-image-preview-operations-wrapper");
      if (mask?.contains(e.target)) return;
      if (checking) return;
      if (!middle && e.target.nodeName !== "IMG") setTouchTimes((times) => times + 1);
    },
    [checking]
  );

  useDebounceEffect(
    () => {
      if (touchTimes >= 2) {
        toggleFullscreen();
      }
      setTouchTimes(0);
    },
    [touchTimes],
    {
      wait: 500,
    }
  );

  return (
    <div
      className={cls({
        [styles.bubbleLeftWrapper]: position === "left",
        [styles.bubbleRightWrapper]: position === "right",
      })}
    >
      <div
        className="bubble-container"
        ref={containRef}
      >
        <div onClick={clickHandler}>
          <div
            className={`bubble-${position}-${messageId} Bubble text`}
            onClick={bubbleClickHandler}
          >
            <div
              style={{ width: 400, whiteSpace: "normal" }}
              className={`audio-${messageId}`}
            />
          </div>
          {checking && position === "left" && (
            <div className="bubble-checkbox-wrapper">
              <Checkbox checked={checkedList.includes(messageId)} />
            </div>
          )}{" "}
        </div>
        {!checking && finished && (
          <BubbleToolbar
            text={text}
            createdAt={createdAt}
            setComposerText={setComposerText}
            messageId={messageId}
            format={isMarkdownFormat}
            formatHandler={markdownFormatToggle}
            position={position}
            isLast={isLast}
            isMusic={true}
          />
        )}
      </div>
    </div>
  );
}

export default memo(MusicBubble);
