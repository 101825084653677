export const theme = {
  state: {
    dark: false,
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: (dispatch) => ({}),
};
