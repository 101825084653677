import { useCallback } from "react";
import { Descriptions, Tooltip, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import cls from "classnames";
import { isEmpty } from "lodash-es";
import useScreenSize from "@/hooks/useScreenSize";

const { Paragraph } = Typography;

function ScienceCard({ props, index }) {
  const {
    abstract,
    attachment,
    author,
    chou,
    data_list,
    doi,
    id,
    keywords,
    publication_date,
    size,
    title,
    url,
    url2,
    year,
  } = props;
  const { middle } = useScreenSize();

  const linkHandler = useCallback(() => {
    window.open(url, "_blank");
  }, [url]);

  return (
    <div className={cls("scienceCard", { widthStyle: !middle })}>
      <Descriptions
        column={2}
        title={
          <Paragraph
            className="title"
            ellipsis={{
              rows: 1,
              expandable: true,
              symbol: "更多",
              onExpand: (e) => e.stopPropagation(),
            }}
            title={`${index}. ${title}`}
            onClick={() => linkHandler()}
          >
            {index}. {title}
          </Paragraph>
        }
        extra={
          <Tooltip title={`DOI:${doi}`}>
            <InfoCircleOutlined />
          </Tooltip>
        }
      >
        <Descriptions.Item label={<div className="label-name">作者</div>}>{author || "无"}</Descriptions.Item>
        <Descriptions.Item label={<div className="label-name">发布日期</div>}>
          {publication_date || "无"}
        </Descriptions.Item>
        <Descriptions.Item label={<div className="label-name">来源</div>}>{attachment || "无"}</Descriptions.Item>
        <Descriptions.Item label={<div className="label-name">关键词</div>}>{keywords || "无"}</Descriptions.Item>
        <Descriptions.Item
          label={<div className="label-name">摘要</div>}
          span={2}
          className="abstract"
        >
          <Paragraph
            ellipsis={{
              rows: 3,
              expandable: true,
              symbol: "更多",
            }}
            title={abstract}
          >
            {abstract || "无"}
          </Paragraph>
        </Descriptions.Item>
        {!isEmpty(data_list) && (
          <Descriptions.Item
            label={<div className="label-name">数据列表</div>}
            span={2}
            className="data-list"
          >
            <Paragraph
              ellipsis={{
                rows: 3,
                expandable: true,
                symbol: "更多",
              }}
              className="data-list-content"
            >
              {data_list.map((data_item, index) => {
                return data_item.url ? (
                  <a
                    href={data_item.url}
                    key={`data-item-${index}`}
                    title={`点击下载${data_item.name}`}
                    className="data-item"
                  >
                    {data_item.name}
                  </a>
                ) : (
                  data_item.name
                );
              })}
            </Paragraph>
          </Descriptions.Item>
        )}
      </Descriptions>
    </div>
  );
}

export default ScienceCard;
