import { Select, Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import styles from "../index.module.less";

function VoiceSelect({ quickReply }) {
  const dispatch = useDispatch();
  const { voice } = useSelector((state) => state.config);
  const options = [
    {
      label: "女声",
      value: "女声",
    },
    {
      label: "男声",
      value: "男声",
    },
  ];

  return (
    <div className={styles.selectorContainer}>
      <span className="selector-label">声音选择</span>
      <Select
        size="small"
        bordered={false}
        defaultValue={voice}
        onChange={(value) => {
          dispatch.config.update({
            ratio: value,
          });
        }}
        style={{ width: "70px" }}
      >
        {options.map((item) => (
          <Select.Option
            key={item.value}
            value={item.value}
          >
            {item.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export default VoiceSelect;
