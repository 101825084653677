import { Button } from "antd";
import { useRef } from "react";
import cls from "classnames";
import { useHover } from "ahooks";
import styles from "./index.module.less";

function ChatSessionStatistics({ count, bulkStartHandler }) {
  const ref = useRef(null);
  const isShow = useHover(ref);
  return (
    <div
      className={cls(styles.sessionStatistics, "sessionStatistics")}
      ref={ref}
    >
      <div className="left">
        <span>共</span>
        <span className="count">{count}</span>
        <span>个对话</span>
      </div>
      <div className="right">
        {isShow ? (
          <>
            <Button
              type="link"
              className="clear-btn"
              onClick={bulkStartHandler}
            >
              批量操作
            </Button>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default ChatSessionStatistics;
