import { useCallback, useState, useEffect, useLayoutEffect, useRef } from "react";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Empty } from "antd";
import { useDispatch } from "react-redux";
import cls from "classnames";
import PhraseItem from "./PhraseItem";
import PhraseEditModal from "./PhraseEditModal";
import styles from "./index.module.less";
import { data as dataService } from "@/services";
import { useRequest, useHover } from "ahooks";
import { isEmpty } from "lodash-es";
import emptyImg from "@/assets/empty.png";

function PhraseBook({ setText, phraseBtnRef }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [editData, setEditData] = useState({});
  const [phraseList, setPhraseList] = useState([]);
  const bookRef = useRef(null);

  useEffect(() => {
    getList();

    // 添加click事件
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (!open && editData) {
      setEditData({});
    }

    // 编辑框打开移除click事件，关闭添加click事件
    if (open) {
      window.removeEventListener("click", handleOutsideClick);
    } else {
      window.addEventListener("click", handleOutsideClick);
    }
  }, [open]);

  const { run: getList } = useRequest(() => dataService.getInstructionList({}), {
    manual: true,
    onSuccess: (res) => {
      if (res.status === "OK") {
        setPhraseList(res.data);
      }
    },
    onError: (err) => {},
  });

  const handleAddPhrase = useCallback(() => {
    setType("add");
    setOpen(true);
  }, []);

  const handleOutsideClick = useCallback((e) => {
    // 点击区域判断，如果点击区域不在bookRef内，过滤按钮点击区域，则关闭编辑框
    if (bookRef.current && !bookRef.current.contains(e.target) && !phraseBtnRef.current.contains(e.target)) {
      dispatch.chat.update({
        phraseStatus: false,
      });
    }
  }, []);

  const items = phraseList?.map((item) => (
    <PhraseItem
      item={item}
      key={item.id}
      setOpen={setOpen}
      setEditData={setEditData}
      setType={setType}
      getList={getList}
      setText={setText}
    />
  ));

  return (
    <div className={cls(styles.PhraseBookContainer, "PhraseBookContainer")}>
      <div
        className="PhraseBookContent"
        ref={bookRef}
      >
        <div className="PhraseBookContentHeader">
          <div className="PhraseBookContentTitle">{isEmpty(items) ? "" : "选择常用语"}</div>
          <CloseOutlined
            onClick={() => {
              dispatch.chat.update({
                phraseStatus: false,
              });
            }}
          />
        </div>
        <div className="PhraseBookContentBody">
          {isEmpty(items) ? (
            <Empty
              description={"点击“添加常用语”按钮收藏自己的指令，可以快速调用哦~"}
              image={emptyImg}
            />
          ) : (
            items
          )}
        </div>
        <div className="PhraseBookContentFooter">
          <div
            className="footerBtn"
            onClick={handleAddPhrase}
          >
            <PlusOutlined />
            添加常用语
          </div>
        </div>
      </div>
      <PhraseEditModal
        type={type}
        open={open}
        setOpen={setOpen}
        getList={getList}
        editData={editData}
      />
    </div>
  );
}

export default PhraseBook;
