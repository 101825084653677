import { init } from "@rematch/core";
import storage from "redux-persist/lib/storage";
import selectPlugin from "@rematch/select";
import loadingPlugin from "@rematch/loading";
import persistPlugin from "@rematch/persist";
import * as models from "./models";

const persistConfig = {
  key: "xuanzang",
  storage,
  whitelist: ["token", "theme"],
};

export const store = init({
  models,
  plugins: [selectPlugin(), loadingPlugin(), persistPlugin(persistConfig)],
});
