import styles from "./index.module.less";
import ThingIcons from "@/icons/ThingIcons";
import { Button } from "antd";
function GuestPopContent({ setOpen }) {
  return (
    <div className={styles.GuestPopContent}>
      <div className="header">快速登录/注册享会员权益：</div>
      <div className="introduction">
        <div className="introduction-content">
          <div className="introduction-content-item">
            <ThingIcons type="benefit" />
            <span>可享免费会员权益</span>
          </div>
          <div className="introduction-content-item">
            <ThingIcons type="lock" />
            <span>解锁8大AI模型</span>
          </div>
        </div>
        <div className="introduction-content">
          <div className="introduction-content-item">
            <ThingIcons type="register" />
            <span>注册即送积分</span>
          </div>
          <div className="introduction-content-item">
            <ThingIcons type="bind" />
            <span>手机/邮箱绑定送积分</span>
          </div>
        </div>
      </div>
      <div className="btn">
        <Button
          type="primary"
          className="login-btn"
          onClick={() => {
            setOpen(true);
          }}
        >
          立即登录/注册
        </Button>
      </div>
    </div>
  );
}

export default GuestPopContent;
