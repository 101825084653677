import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import cls from "classnames";
import styles from "./index.module.less";
import useScreenSize from "@/hooks/useScreenSize";

function Sider({ className, children, showSider, showHandle = false, onHandleClick = () => {} }) {
  const { middle } = useScreenSize();
  return (
    <div className={cls(styles.sider, className, "sider")}>
      <div
        className="sider-body"
        style={{ width: !middle && showSider ? "97%" : "100%" }}
      >
        {children}
        {showHandle ? (
          <div
            className="sider-toggle"
            onClick={onHandleClick}
          >
            {showSider ? <LeftOutlined /> : <RightOutlined />}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Sider;
