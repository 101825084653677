import { useCallback } from "react";
import { Button } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { feedback } from "@/utils/index";

function DownloadButton({ id, title }) {
  const downloadHandler = useCallback(
    async (e) => {
      e.stopPropagation();
      try {
        const date = Date.now();
        let iframe = document.createElement("iframe");
        iframe.src = `https://chat.phitrellis.com/api/session/${id}/download/`;
        iframe.style.display = "none";
        iframe.id = `iframe-${date}`;
        document.body.appendChild(iframe);
        setTimeout(() => {
          document.body.removeChild(iframe);
        }, 1000);
      } catch (err) {
        feedback(err.data || "内容导出失败！");
      }
    },
    [title, id]
  );

  return (
    <Button
      size="small"
      type="text"
      icon={<SaveOutlined />}
      onClick={downloadHandler}
    >
      导出内容
    </Button>
  );
}

export default DownloadButton;
