import { Button } from "antd";
import { useCallback } from "react";
import { EditOutlined } from "@ant-design/icons";

function RenameButton({ setEditing }) {
  const renameHandler = useCallback((e) => {
    e.stopPropagation();
    setEditing(true);
  }, []);

  return (
    <Button
      block
      size="small"
      icon={<EditOutlined />}
      onClick={renameHandler}
      type="text"
    >
      重命名
    </Button>
  );
}

export default RenameButton;
