import { Button, Checkbox, Popconfirm } from "antd";
import { useEffect, useRef, useState } from "react";
import cls from "classnames";
import styles from "./index.module.less";

function ChatSessionStatistics({
  total = 0,
  bulkSelected = [],
  bulkCancelHandler,
  updateBulkAllHandler,
  bulkRemoveHandler,
}) {
  const ref = useRef(null);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  useEffect(() => {
    setChecked(total === bulkSelected.length);
    setIndeterminate(bulkSelected.length === 0 ? false : total > 0 && bulkSelected.length < total);
  }, [total, bulkSelected]);

  useEffect(() => {
    updateBulkAllHandler({ indeterminate, checked });
  }, [checked]);

  return (
    <div
      className={cls(styles.sessionStatistics, "sessionStatistics")}
      ref={ref}
    >
      <div className="left">
        <Checkbox
          disabled={total <= 0}
          checked={checked}
          indeterminate={indeterminate}
          onChange={(e) => {
            setChecked(e.target.checked);
            setIndeterminate(false);
          }}
        />
        <span>&nbsp;(</span>
        <span className={cls("count", { "count-isolate": true })}>{bulkSelected?.length || 0}</span>
        <span>)</span>
      </div>
      <div className="right">
        {bulkSelected.length ? (
          <>
            <Popconfirm
              title={`确认批量删除选中的 ${bulkSelected.length} 个对话？`}
              okText="删除"
              cancelText="取消"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onConfirm={bulkRemoveHandler}
              onCancel={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              overlayStyle={{ width: "200px" }}
            >
              <Button
                type="link"
                className="clear-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                disabled={bulkSelected.length <= 0}
                key="remove"
                style={{ color: "var(--status-error)" }}
              >
                删除
              </Button>
            </Popconfirm>
          </>
        ) : null}
        <Button
          type="link"
          className="clear-btn"
          onClick={bulkCancelHandler}
          key="delete"
        >
          取消
        </Button>
      </div>
    </div>
  );
}

export default ChatSessionStatistics;
