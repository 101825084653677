import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import useScreenSize from "@/hooks/useScreenSize";
import { Button, Tooltip, message } from "antd";
import { useMatch } from "react-router-dom";
import { FormOutlined } from "@ant-design/icons";

function RefineButton({ quickReply, onRefineSend, initialValue, appendMsg, messageList }) {
  const { middle } = useScreenSize();
  const { avatar = "/user.png" } = useSelector((state) => state.token);
  const matchAtChat = useMatch("/chat");
  const [open, setOpen] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const openHandler = useCallback(() => {
    const isExist = messageList.some((item) => item._id === "edit-refine");
    if (isExist) {
      message.error("当前会话已有编辑框");
      return;
    }

    appendMsg({
      _id: "edit-refine",
      id: `${Date.now()}-answer`,
      type: "normal-message",
      content: {
        message_type: "refine",
        showButtons: true, // 编辑框功能按钮区域
      },
      position: "right",
      user: {
        avatar: avatar,
      },
    });
  }, [messageList, appendMsg, avatar]);

  return (
    <Tooltip
      placement="top"
      title={"输入原始文本和与之重复文本，GPT自动对比后降重"}
      open={openTooltip}
    >
      {middle ? (
        <Button
          shape="round"
          size="small"
          className="input-tools-btn"
          onClick={openHandler}
          onMouseEnter={() => setOpenTooltip(true)}
          onMouseLeave={() => setOpenTooltip(false)}
        >
          高级降重
        </Button>
      ) : (
        <Button
          size="large"
          type="text"
          icon={<FormOutlined style={{ color: "var(--brand-1)" }} />}
          onClick={openHandler}
          onMouseEnter={() => setOpenTooltip(true)}
          onMouseLeave={() => setOpenTooltip(false)}
        ></Button>
      )}
    </Tooltip>
  );
}

export default RefineButton;
