import { PaperClipOutlined, CloudUploadOutlined } from "@ant-design/icons";

function uploadConfig(quickReply) {
  switch (quickReply) {
    case "数据分析":
      return {
        accept: ".csv,.txt,.xlsx",
        limitSize: 10,
        title: "上传表格数据",
        icon: <CloudUploadOutlined />,
      };
    case "批处理":
      return {
        accept: ".xlsx",
        limitSize: 10,
        title: "上传任务文件，每行一个",
        icon: <CloudUploadOutlined />,
      };
    case "OFFICE密码破解":
      return {
        accept: ".xlsx,.xls,.doc,.docx,.ppt,.pptx",
        limitSize: 20,
        callback: true, // 直接提问
        title: "上传OFFICE文件",
        icon: <CloudUploadOutlined />,
      };
    case "语音转文字":
      return {
        accept: ".mp3,.mp4,.mpeg,.mpga,.m4a,.wav,.webm",
        limitSize: 2,
        callback: true, // 直接提问
        title: "上传音频",
        icon: <CloudUploadOutlined />,
      };
    case "OCR图文识别":
    case "图片解释":
    case "代码重现":
    case "图生论文":
    case "图表数字化":
      return {
        accept: "image/*",
        limitSize: null,
        title: "上传图表",
        icon: <CloudUploadOutlined />,
      };
    case "文章读写":
    case "文章重组":
    case "文章优化":
      return {
        accept: ".pdf,.docx,.doc,.txt",
        limitSize: 10,
        title: "上传文档",
        icon: <CloudUploadOutlined />,
      };
    case "灵知":
    case "开题报告":
    case "论文撰写":
    case "文献综述":
      return {
        accept: ".enw,.txt,.ciw,.ris",
        limitSize: 1,
        title: "上传知网、万方、WOS等导出的带摘要的EndNote文件，支持多个",
        icon: <CloudUploadOutlined />,
      };
    case undefined:
    case null:
      return {
        accept: "*",
        limitSize: 10,
        title: "上传图表、文献、文档、方案、笔记、数据、代码等等，支持不超过10页的扫描版PDF，支持多个文档",
        icon: <PaperClipOutlined />,
      };
    default:
      break;
  }
}

export function getUploadConfig(quickReply) {
  return uploadConfig(quickReply);
}
