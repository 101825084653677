import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, notification, Modal } from "antd";
import ActionIcons from "@/icons/ActionIcons";
import { feedback } from "@/utils/index";
import style from "./TextBubbleAction.module.less";
import { useRequest } from "ahooks";
import { data as dataService } from "@/services/data";
import { formatOutline } from "../OutlineBubble/template";

function TextBubbleAction({ children, messageId, status, setComposerText, actionText, actionList, text, outlineRef }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [writed, setWrited] = useState(false);

  const { run: changeStatus } = useRequest(
    (id, outline) =>
      dataService.changeMessageStatus(id, {
        outline,
      }),
    {
      manual: true,
      onSuccess: (res) => {
        // 刷新message list
        dispatch.chat.update({
          version: Date.now(),
        });
        dispatch.document.update({
          version: Date.now(),
        });
      },
      onError: (err) => {
        Modal.error({
          autoFocusButton: "ok",
          centered: true,
          closable: true,
          content: err.data,
        });
      },
    }
  );

  const actionClickHandler = useCallback(
    async (e, item) => {
      switch (item.type) {
        case "switch-to-plugin":
          navigate(item.path, { replace: true, show: true });
          dispatch.chat.update({
            sessionId: undefined,
            quickReply: item.label,
            replyVersion: Date.now(),
          });
          if (item.fill && text) {
            setTimeout(() => {
              if (writed) return;
              const el = document.querySelector(".Composer-input");
              el.focus();
              setComposerText(text);
              setWrited(true);
              setTimeout(() => {
                setWrited(false);
              }, 3000);
            }, 100);
          }
          break;
        case "export-word":
          e.stopPropagation();
          try {
            const date = Date.now();
            let iframe = document.createElement("iframe");
            iframe.src = `https://chat.phitrellis.com/api/data/${messageId}/download_docx/`;
            iframe.style.display = "none";
            iframe.id = `iframe-${date}`;
            document.body.appendChild(iframe);
            setTimeout(() => {
              document.body.removeChild(iframe);
            }, 1000);
          } catch (err) {
            feedback(err.data || "内容导出失败！");
          }
          break;
        case "edit-outline":
          dispatch.chat.update({
            editOutlineData: text,
          });
          break;
        case "edit-stop":
          // 更改message状态
          changeStatus(messageId);
          // 返回底部
          const target = document.querySelector(".message-scrollbar");
          target.scrollTo({ top: target.scrollHeight, behavior: "smooth" });
          break;
        case "create-article":
          // 更改message状态
          const validate = outlineRef.current.validateResult();
          if (validate) return;
          changeStatus(messageId, formatOutline(outlineRef.current.outline));
          break;
      }
    },
    [text, setComposerText, writed]
  );

  return Number(status) === 3 ? (
    children
  ) : (
    <div className={style.textBubbleActionWrapper}>
      {children}
      {actionList && actionList.length ? (
        <div className="bubble-action">
          {actionText ? <p>{actionText || ""}</p> : null}
          <div className="bubble-action-buttons">
            {(actionList || []).map((item) => (
              <Button
                key={item.label}
                type="primary"
                // size="small"
                className="QuickReply"
                onClick={(e) => actionClickHandler(e, item)}
                icon={item.icon ? <ActionIcons type={item.icon} /> : ""}
              >
                {/* {item.icon ? <ActionIcons type={item.icon} /> : ""} */}
                {item.label}
              </Button>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default TextBubbleAction;
