import { useCallback, useEffect } from "react";
import { Select, Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useMatch } from "react-router-dom";
import styles from "../index.module.less";

function DBSelect({ quickReply }) {
  const dispatch = useDispatch();
  const { db, knowledge } = useSelector((state) => state.config);
  const matchAtChat = useMatch("/chat");
  const { Option } = Select;

  return (
    <div className={styles.selectorContainer}>
      <span className="selector-label">网络文献</span>
      <Select
        size="small"
        bordered={false}
        value={db}
        onChange={(value) => {
          dispatch.config.update({
            db: value,
          });
        }}
        style={{ width: "90px" }}
        disabled={knowledge !== null}
      >
        <Option
          value="综合库"
          label="综合库"
        >
          <Tooltip
            placement="top"
            title={"Semantic综合论文库"}
          >
            <div>综合库</div>
          </Tooltip>
        </Option>
        <Option
          value="知网"
          label="知网"
        >
          <Tooltip
            placement="top"
            title={"CNKI-中国知网，耗时较长"}
          >
            <div>知网</div>
          </Tooltip>
        </Option>
        <Option
          value="PubMed"
          label="PubMed"
        >
          <Tooltip
            placement="top"
            title={"PubMed医学论文库"}
          >
            <div>PubMed</div>
          </Tooltip>
        </Option>
        <Option
          value="全库"
          label="全库"
        >
          <Tooltip
            placement="top"
            title={"3种论文库全部检索，耗时较长"}
          >
            <div>全库</div>
          </Tooltip>
        </Option>
      </Select>
    </div>
  );
}

export default DBSelect;
