import { Modal, Form, Input, Select, Button, Space, Row, Col } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

const SheetConfigModal = ({ open, setOpen, setConfigName, onClick, type }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const options = [
    {
      value: "str",
      label: "字符串",
    },
    {
      value: "int",
      label: "整数",
    },
    {
      value: "float",
      label: "小数",
    },
    {
      value: "bool",
      label: "布尔",
    },
    {
      value: "date",
      label: "日期",
    },
    {
      value: "datetime",
      label: "日期时间",
    },
    {
      value: "time",
      label: "时间",
    },
  ];
  return (
    <Modal
      title="提取配置"
      wrapClassName="evidenceModal"
      width={600}
      bodyStyle={{ height: "450px", overflowY: "auto" }}
      open={open}
      destroyOnClose={true}
      onCancel={() => {
        setOpen(false);
      }}
      onOk={() => {
        form.validateFields().then((values) => {
          dispatch.config.update({
            json_schema: values,
          });
          setOpen(false);
          if (type === "document") {
            onClick("text", "信息提取", values);
            return;
          }
          setConfigName(values.name);
        });
      }}
    >
      <Form
        form={form}
        initialValues={{
          is_list: true,
          fields: [
            {
              type: "str",
            },
          ],
        }}
      >
        <Form.Item
          label="名称"
          name="name"
          labelCol={{ span: 3 }}
          rules={[{ required: true, message: "请输入名称" }]}
        >
          <Input placeholder="请输入名称(英文最佳)" />
        </Form.Item>
        <Form.Item
          label="描述"
          labelCol={{ span: 3 }}
          name="description"
        >
          <Input placeholder="请输入描述" />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 4 }}
          label="是否数组"
          name="is_list"
          style={{ display: "none" }}
        >
          <Select>
            <Select.Option value={true}>是</Select.Option>
            <Select.Option value={false}>否</Select.Option>
          </Select>
        </Form.Item>
        <div style={{ marginBottom: "10px" }}>
          <span>属性列表:</span>
        </div>
        <Form.List name="fields">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Space
                  key={key}
                  style={{ display: "flex", justifyContent: "space-between" }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "name"]}
                    rules={[{ required: true, message: "请输入属性名称" }]}
                  >
                    <Input placeholder="请输入提取属性名称" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "type"]}
                  >
                    <Select
                      style={{ width: "120px" }}
                      placeholder="请选择类型"
                      options={options}
                    ></Select>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "description"]}
                  >
                    <Input placeholder="请输入描述" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item labelCol={{ span: 4 }}>
                <Button
                  type="dashed"
                  onClick={() =>
                    add({
                      type: "str",
                    })
                  }
                  block
                  icon={<PlusOutlined />}
                >
                  添加属性
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default SheetConfigModal;
