import { Select, Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useMatch } from "react-router-dom";
import styles from "../index.module.less";

function PolishType({ quickReply }) {
  const dispatch = useDispatch();
  const { polish_type } = useSelector((state) => state.config);
  const options = [
    {
      label: "贴近原文",
      value: "贴近原文",
    },
    {
      label: "保留参考文献",
      value: "保留参考文献",
    },
    {
      label: "更简洁",
      value: "更简洁",
    },
    {
      label: "更完整",
      value: "更完整",
    },
    {
      label: "逻辑更清晰",
      value: "逻辑更清晰",
    },
    {
      label: "更流畅",
      value: "更流畅",
    },
  ];

  return (
    <div className={styles.selectorContainer}>
      <span className="selector-label">润色风格</span>
      <Select
        size="small"
        bordered={false}
        onChange={(value) => {
          dispatch.config.update({
            polish_type: value,
          });
        }}
        placeholder="请选择"
        style={{ width: "110px" }}
      >
        {options.map((item) => (
          <Select.Option
            key={item.value}
            value={item.value}
          >
            {item.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export default PolishType;
