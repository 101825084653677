import { useRef } from "react";
import { useHover } from "ahooks";
import { Badge } from "antd";
import PhraseItemAction from "./PhraseItemAction";
import "./index.module.less";

function PhraseItem({ item, setOpen, setType, setEditData, getList, setText }) {
  const ref = useRef(null);
  const isHover = useHover(ref);
  return (
    <div
      className="PhraseBookContentItem"
      key={item.id}
      ref={ref}
      onClick={(e) => {
        setText(item.instruction);
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Badge
        className="custom-badge"
        color={isHover ? "rgba(27, 124, 252, 1)" : "rgb(166, 166, 166)"}
      />
      <span className="title">【{item.title}】</span>
      <span className="content">{item.instruction}</span>
      {isHover && (
        <PhraseItemAction
          item={item}
          setOpen={setOpen}
          setEditData={setEditData}
          setType={setType}
          getList={getList}
        />
      )}
    </div>
  );
}

export default PhraseItem;
