import { useCallback, Fragment, useState, useEffect, useRef } from "react";
import { Button, Modal, Tree, Tooltip, Icon, Space, Spin } from "antd";
import { useRequest, useTrackedEffect } from "ahooks";
import { useSelector, useDispatch } from "react-redux";
import { useMatch } from "react-router-dom";
import { data as dataService } from "@/services/data";
import styles from "./index.module.less";
import { CloseCircleOutlined } from "@ant-design/icons";
import FileColorIcons from "@/icons/FileColorIcons";
import cls from "classnames";

const typeList = ["DOC", "DOCX", "PDF", "PPT", "PPTX", "XLS", "XLSX", "TXT", "CSV", "EPUB"];
const updateTreeData = (list, key, children) => {
  return list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });
};

const getIcon = (node) => {
  if (node.type === 2) return <FileColorIcons type="Folder" />;
  const type = node.paper_info?.ext.toUpperCase();
  return typeList.includes(type) ? <FileColorIcons type={type} /> : <FileColorIcons type="NORMAL" />;
};

function KnowledgeSelect({ quickReply }) {
  const dispatch = useDispatch();
  const matchAtChat = useMatch("/chat");
  const [show, setShow] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState("");
  const { knowledge } = useSelector((state) => state.config);
  const { sessionId } = useSelector((state) => state.chat);

  useTrackedEffect(
    (changes, previousDeps, currentDeps) => {
      // 1、新会话ssessionId从undefined变为有值，此时不能重置，选中状态保持
      // 2、切换对话重置，切换对话有两种情况sessionId和quickReply都变化和仅sessionId变化
      // 3、sessionId未生成，切换对话
      if (
        changes.length > 1 ||
        previousDeps[1] !== currentDeps[1] ||
        (previousDeps[0] && previousDeps[0] !== currentDeps[0])
      ) {
        dispatch.config.update({
          knowledge: null,
          db: "综合库",
        });
      }
    },
    [sessionId, quickReply]
  );

  const handleOk = useCallback(() => {
    if (selectedKeys.length > 0) {
      dispatch.config.update({
        knowledge: {
          id: selectedKeys[0],
          name: selectedTitle,
        },
        db: "",
      });
    }
    setShow(false);
    setTreeData([]);
    setSelectedTitle("");
    setSelectedKeys([]);
  });

  const handleCancel = useCallback(() => {
    setTreeData([]);
    setSelectedKeys([]);
    setSelectedTitle("");
    setShow(false);
  });

  const { loading } = useRequest(
    () =>
      dataService.getSessions({
        type: 1,
      }),
    {
      ready: !!show,
      refreshDeps: [],
      onSuccess: (response) => {
        if (response.status === "OK") {
          const d = response.data.map((item) => {
            return {
              title: item.title,
              key: item.id,
              isLeaf: item.type === 1,
              icon: getIcon(item),
              type: item.type,
            };
          });
          setTreeData(d);
        }
      },
    }
  );

  const loadHandler = useCallback(
    async ({ key, children }) => {
      if (children) {
        return;
      }
      const response = await dataService.getSessions({
        type: 1,
        parent: key,
      });

      const d = response.data.map((item) => {
        return {
          title: item.title,
          key: item.id,
          isLeaf: item.type === 1,
          icon: getIcon(item),
          type: item.type,
        };
      });

      setTreeData((origin) => updateTreeData(origin, key, d));
    },
    [setTreeData]
  );

  const selectHandler = useCallback((key, e) => {
    if (e.node.isLeaf) return; // 如果是叶子节点，则不可被选中
    setSelectedTitle(e.node.title);
    setSelectedKeys(key);
  }, []);

  return (
    <Fragment>
      <div className={styles.knowledgeSelect}>
        <Tooltip
          title="选择在文献版块创建的目录，分析在其中上传的文献进行回答，选择个人文献后不再检索网络文献"
          placement="top"
        >
          <Button
            shape="round"
            size="small"
            className="input-tools-btn"
            onClick={() => {
              setShow(true);
              if (knowledge !== null) {
                setSelectedKeys([knowledge.id]);
                setSelectedTitle(knowledge.title);
              }
            }}
          >
            {knowledge ? `个人文献:${knowledge.name}` : "个人文献"}
            {knowledge && (
              <CloseCircleOutlined
                style={{ zIndex: 3 }}
                onClick={(e) => {
                  setSelectedKeys([]);
                  dispatch.config.update({
                    knowledge: null,
                    db: "综合库",
                  });
                  e.stopPropagation();
                }}
              />
            )}
          </Button>
        </Tooltip>
      </div>
      <Modal
        title="请选择包含文献的目录"
        open={show}
        destroyOnClose={true}
        bodyStyle={{ height: "450px", overflowY: "auto" }}
        wrapClassName={styles.knowledgeModal}
        width={640}
        closable={false}
        footer={
          <div className="knowledgeModalFooter">
            <div className="footerLeft">
              <span>{selectedTitle ? `已选择: "${selectedTitle}"` : ""}</span>
            </div>
            <div className="footerRight">
              <Button
                shape="round"
                onClick={handleCancel}
              >
                取消
              </Button>
              <Button
                type="primary"
                onClick={handleOk}
                shape="round"
              >
                确认
              </Button>
            </div>
          </div>
        }
      >
        <div style={{ minHeight: 200 }}>
          {loading ? (
            <Spin
              tip="加载中..."
              style={{ display: "block" }}
            />
          ) : (
            <Tree.DirectoryTree
              showLine={false}
              blockNode={true}
              onSelect={selectHandler}
              loadData={loadHandler}
              treeData={treeData}
              selectedKeys={selectedKeys}
              titleRender={(nodeData) => {
                return (
                  <div
                    style={{
                      width: "450px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    className={cls("treeTitle")}
                  >
                    <span>{nodeData.title}</span>
                    {nodeData.children && nodeData.children.length >= 0 && (
                      <span style={{ position: "absolute", right: 0 }}>
                        {nodeData.children.filter((item) => item.type === 1).length}篇
                      </span>
                    )}
                  </div>
                );
              }}
            />
          )}
        </div>
      </Modal>
    </Fragment>
  );
}

export default KnowledgeSelect;
