import { Fragment } from "react";
import { Button, Tooltip } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

function PhraseBookButton({ phraseBtnRef }) {
  const { phraseStatus: status } = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  return (
    <Fragment>
      <Tooltip
        placement="top"
        title={"选择常用语"}
      >
        <Button
          ref={phraseBtnRef}
          size="large"
          type="text"
          icon={<MessageOutlined style={{ color: "var(--brand-1)" }} />}
          onClick={() => {
            dispatch.chat.update({
              phraseStatus: !status,
            });
          }}
        />
      </Tooltip>
    </Fragment>
  );
}

export default PhraseBookButton;
