import { useSelector } from "react-redux";
import { List, ListItem } from "antd";
import TextBubble from "../Bubble/TextBubble";
import BubbleToolbar from "../BubbleToolbar";
import ArxivCard from "./ArxivCard";
import styles from "./index.module.less";

const ArxivBubble = ({ text, status, finished, createdAt, setComposerText, messageId, isLast }) => {
  const { checking } = useSelector((state) => state.user);

  try {
    const itemList = JSON.parse(text);
    return (
      <List className={styles.arxivBubble}>
        <List.Item
          className="result-reply"
          content={`以下是为您找到的 ${itemList.length} 篇论文，如需分析请使用学术问答插件`}
        />
        {itemList.map((item, index) => {
          return (
            <List.Item
              key={`listitem-${index}`}
              children={
                <ArxivCard
                  props={item}
                  index={index + 1}
                />
              }
            />
          );
        })}
        {!checking && finished && (
          <BubbleToolbar
            text={text}
            createdAt={createdAt}
            setComposerText={setComposerText}
            messageId={messageId}
            isLast={isLast}
            position={"left"}
            isScholar={true}
          />
        )}
      </List>
    );
  } catch (error) {
    return (
      <TextBubble
        text={text}
        status={status}
        finished={finished}
        setComposerText={setComposerText}
        messageId={messageId}
      />
    );
  }
};

export default ArxivBubble;
