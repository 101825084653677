import { useSelector, useDispatch } from "react-redux";
import cls from "classnames";
import { useClickAway } from "ahooks";
import useScreenSize from "@/hooks/useScreenSize";
import Sider from "@/components/common/Sider";
import TopMessage from "@/components/common/TopMessage";
import WindowHeader from "@/components/common/WindowHeader";
import FloatSider from "@/components/common/FloatSider";
import SessionList from "../SessionList";
import ChatWindow from "../ChatWindow";
import Noticebar from "./NoticeBar";
import ChatHome from "../ChatHome";
import MobileEditableTitle from "@/components/common/WindowHeader/MobileEditableTitle";
import styles from "./index.module.less";

function WindowBody({ showSider, siderHandler }) {
  useClickAway(() => {
    siderHandler(false);
  }, [document.querySelector(".absolute"), document.querySelector(".anticon-menu-unfold")]);

  const { quickReply, sessionId, chatAtHome } = useSelector((state) => state.chat);
  const { chatPromptList, chatTutorUrlList, chatFaqUrlList, chatPlaceholderList } = useSelector((state) => state.app);
  const { messageList } = useSelector((state) => state.user);
  const { middle } = useScreenSize();
  const dispatch = useDispatch();

  return (
    <div className={styles.windowBd}>
      <div className="window-body-container">
        <Sider
          onHandleClick={() => siderHandler(!showSider)}
          showHandle={true}
          showSider={showSider}
          className={cls({
            absolute: true,
            "full-width": showSider,
            "zero-width": !showSider,
          })}
          children={
            <SessionList
              siderHandler={siderHandler}
              placeholderList={chatPlaceholderList}
            />
          }
        />
        <div
          className={cls("window-body-inner", {
            "full-width": !showSider,
            "zero-width": showSider,
          })}
          style={{
            paddingLeft: showSider ? "300px" : 0,
          }}
        >
          <WindowHeader
            showSider={showSider}
            siderHandler={siderHandler}
          />
          {messageList.length > 0 && <Noticebar messageList={messageList} />}
          {/* 手机版title */}
          {!middle && <MobileEditableTitle siderHandler={siderHandler} />}
          {quickReply && (
            <TopMessage
              quickReply={quickReply}
              prompts={chatPromptList}
            />
          )}
          {middle ? (
            <FloatSider
              quickReply={quickReply}
              tutorUrl={chatTutorUrlList[quickReply]}
              faqUrl={chatFaqUrlList[quickReply]}
            />
          ) : null}
          {/* <ChatWindow /> */}
          {chatAtHome ? <ChatHome /> : <ChatWindow />}
        </div>
      </div>
    </div>
  );
}

export default WindowBody;
