import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CommentOutlined, FilePdfOutlined } from "@ant-design/icons";
import styles from "./index.module.less";
import classNames from "classnames";
import LeftMenuBottom from "./LeftMenuBottom";
import useScreenSize from "@/hooks/useScreenSize";

function LeftMenu({ showSider, siderHandler }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { middle } = useScreenSize();

  const navigateHandler = useCallback(
    async (to) => {
      switch (pathname) {
        case "/chat":
          navigate(to, { replace: true, show: true });
          dispatch.chat.resetSession();
          break;
        case "/document":
          navigate(to, { replace: true, show: true });
          dispatch.document.resetSession();
          break;
        default:
          break;
      }
      dispatch.user.resetCheck();
    },
    [dispatch, pathname]
  );

  return (
    <div
      className={styles.leftMenu}
      style={{ display: (!middle && showSider) || middle ? "block" : "none" }} // 大屏始终显示leftmenu 小屏幕和sider同步收缩展开
    >
      <div
        onClick={() => {
          navigateHandler("/chat");
          dispatch.chat.update({
            chatAtHome: true,
          });
        }}
        className="leftMenu-logo"
      >
        <img
          src="/logo192.png"
          alt="logo"
        />
      </div>
      <div className={classNames("leftMenu-main")}>
        <div
          onClick={() => {
            navigateHandler("/chat");
            dispatch.chat.update({
              chatAtHome: true,
            });
          }}
          className={classNames("leftMenu-main-item", "tour-chat", { active: pathname === "/chat" })}
        >
          <CommentOutlined />
          <span>对话</span>
        </div>
        <div
          onClick={() => navigateHandler("/document")}
          className={classNames("leftMenu-main-item", "tour-document", { active: pathname === "/document" })}
        >
          <FilePdfOutlined />
          <span>文献</span>
        </div>
      </div>
      <LeftMenuBottom />
    </div>
  );
}

export default LeftMenu;
