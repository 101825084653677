import { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip, Button, Badge, message } from "antd";
import { BellOutlined } from "@ant-design/icons";
import { useRequest } from "ahooks";
import { data as dataService } from "@/services";
import LeftBottomIcons from "@/icons/LeftBottomIcons";

function MessageButton() {
  const { jwt } = useSelector((state) => state.token);
  const { refeashMessage } = useSelector((state) => state.user);
  const [messageCount, setMessageCount] = useState(0);
  const dispatch = useDispatch();

  const { run: getMessageList } = useRequest(() => dataService.getUserMessage({ checked: false }), {
    manual: true,
    ready: !!jwt,
    refreshDeps: [jwt],
    onSuccess: (res) => {
      if (res.data) {
        setMessageCount(res.data.count);
        dispatch.user.update({ messageList: res.data.results });
        dispatch.user.update({ refeashMessage: false });
      }
    },
    onError: (err) => {
      message.error(err.data);
    },
  });

  useEffect(() => {
    getMessageList();
  }, []);

  useEffect(() => {
    refeashMessage && getMessageList();
  }, [refeashMessage]);

  return (
    <Fragment>
      <Badge
        size="small"
        count={messageCount}
      >
        <Tooltip
          title="消息中心"
          placement="right"
        >
          <a href="/console/message">
            <div className="leftMenuBottom-Btn message-button">
              {/* <BellOutlined style={{ cursor: "pointer", fontSize: 20 }} /> */}
              <LeftBottomIcons type="message" />
            </div>
          </a>
        </Tooltip>
      </Badge>
    </Fragment>
  );
}

export default MessageButton;
