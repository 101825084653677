import { Modal, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

const EvidenceModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const { pubSearchStrategy } = useSelector((state) => state.config);
  return (
    <Modal
      title="检索策略"
      wrapClassName="evidenceModal"
      width={600}
      open={open}
      destroyOnClose={true}
      onCancel={() => {
        setOpen(false);
      }}
      onOk={() => {
        dispatch.config.update({
          pubSearchStrategy: searchText,
        });
        setOpen(false);
      }}
    >
      <Input.TextArea
        placeholder="请输入检索策略"
        autoSize={{ minRows: 1, maxRows: 6 }}
        defaultValue={pubSearchStrategy}
        allowClear={true}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
    </Modal>
  );
};

export default EvidenceModal;
