export const counter = {
  state: {
    dark: 0,
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: (dispatch) => ({}),
};
