import { useCallback } from "react";
import { Select, Tooltip, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useMatch } from "react-router-dom";
import styles from "../index.module.less";

function LanguageSelect({ quickReply }) {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.config);
  const options = [
    {
      label: "中文",
      value: "中文",
    },
    {
      label: "英文",
      value: "英文",
    },
    {
      label: "俄语",
      value: "俄语",
    },
    {
      label: "日语",
      value: "日语",
    },
    {
      label: "韩语",
      value: "韩语",
    },
    {
      label: "德语",
      value: "德语",
    },
    {
      label: "法语",
      value: "法语",
    },
    {
      label: "意大利语",
      value: "意大利语",
    },
    {
      label: "西班牙语",
      value: "西班牙语",
    },
    {
      label: "丹麦语",
      value: "丹麦语",
    },
    {
      label: "拉丁语",
      value: "拉丁语",
    },
    {
      label: "阿拉伯语",
      value: "阿拉伯语",
    },
  ];

  return (
    <div className={styles.selectorContainer}>
      <span className="selector-label">目标语言</span>
      <Select
        size="small"
        bordered={false}
        defaultValue={language}
        options={options}
        onChange={(value) => {
          dispatch.config.update({
            language: value,
          });
        }}
        style={{ width: "80px" }}
      ></Select>
    </div>
  );
}

export default LanguageSelect;
