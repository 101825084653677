import { useCallback } from "react";
import cls from "classnames";
import useScreenSize from "@/hooks/useScreenSize";
import { isEmpty } from "lodash-es";

function PubmedCard({ props, index }) {
  const { abstract, author, copyrights, doi, journal, keywords, publication_date, pubmed_id, title } = props;
  const { middle } = useScreenSize();

  const linkHandler = useCallback((url) => {
    window.open(`https://pubmed.ncbi.nlm.nih.gov/${url}/`, "_blank");
  }, []);

  return (
    <div className={cls("pubmedCard", { widthStyle: !middle })}>
      <div className="header">
        <div
          className="title"
          title={`${index}. ${title}`}
          onClick={() => linkHandler(pubmed_id)}
        >
          {index}. {title}
        </div>
        <div className="publish">{publication_date}</div>
      </div>
      <div className="intruduce">
        <div className="authors">
          <strong>Authors: </strong>
          {author?.map((a, index) => (
            <span
              key={`author-${index}`}
              className="author"
            >
              {`${a.name}`}
              {index !== author.length - 1 && ", "}
            </span>
          ))}
        </div>
      </div>
      <div className="journal">
        <div>
          <strong>Journal: </strong>
          {journal}
        </div>
        <div className="doi">
          <strong>DOI: </strong>
          {doi}
        </div>
      </div>
      <div className="abstract">
        <div className="abstract-content">
          <div
            className="introduction"
            title={abstract}
          >
            <strong>Abstract: </strong>
            {abstract || "无"}
          </div>
        </div>
        <div className="keyword">
          <strong>Keywords: </strong>
          {isEmpty(keywords) ? "无" : keywords?.map((keyword) => `${keyword}; `)}
        </div>
      </div>
    </div>
  );
}

export default PubmedCard;
