import { useCallback } from "react";
import { Descriptions, Divider, Typography } from "antd";
import cls from "classnames";
import useScreenSize from "@/hooks/useScreenSize";

const { Paragraph, Text } = Typography;

function FullTextCard({ props, index }) {
  const { author, badges, doi, journal, pages, paper_id, text, title, url, volume, year } = props;
  const { middle } = useScreenSize();

  const linkHandler = useCallback((url) => {
    window.open(url, "_blank");
  }, []);

  return (
    <div className={cls("fulltextCard", { widthStyle: !middle })}>
      <Descriptions
        column={2}
        title={
          <Paragraph
            className="title"
            ellipsis={{
              rows: 1,
              expandable: true,
              symbol: "更多",
              onExpand: (e) => e.stopPropagation(),
            }}
            title={`${index}. ${title}`}
            onClick={() => linkHandler(url)}
          >
            {index}. {title}
          </Paragraph>
        }
      />
      <Paragraph
        className="content"
        ellipsis={{
          rows: 3,
          expandable: true,
          symbol: "more",
        }}
        title={text}
      >
        <span>匹配正文：</span> {text}
      </Paragraph>
      <div className="intruduce">
        <Text className="journal">{journal}</Text>
        <Divider type="vertical" />
        <Text
          className="auth"
          title={author}
          ellipsis={{ rows: 1 }}
        >
          {author.map((a, index) => {
            return (
              <span
                key={`author-${index}`}
                className="author"
              >
                {a}
              </span>
            );
          })}
        </Text>
        <Divider type="vertical" />
        <Text className="publish">{year}</Text>
      </div>
    </div>
  );
}

export default FullTextCard;
