import { Fragment, useCallback, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useRequest } from "ahooks";
import { Button, Input, Spin, Empty, message } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars-2";
import { isEmpty } from "lodash-es";
import { data as dataService } from "@/services/data";
import { feedback } from "@/utils/index";
import useScreenSize from "@/hooks/useScreenSize";
import ChatSessionItem from "./ChatSessionItem";
import ChatSessionStatistics from "./ChatSessionStatistics";
import ChatSessionBulk from "./ChatSessionBulk";
import cls from "classnames";
import ActionIcons from "@/icons/ActionIcons";
import styles from "./index.module.less";

function SessionList({ siderHandler, placeholderList }) {
  const dispatch = useDispatch();
  const { sessionId, latestSessionId, quickReply } = useSelector((state) => state.chat);
  const { jwt, hadLogin } = useSelector((state) => state.token);
  const { middle, xlarge } = useScreenSize();
  const [bulkStatus, setBulkStatus] = useState(false);
  const [bulkSelected, setBulkSelected] = useState([]);
  const [bulkIsAll, setBulkIsAll] = useState([]);
  const [bulkIIndeterminate, setBulkIIndeterminate] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(xlarge ? 30 : 20);
  const [loadedList, setLoadedList] = useState([]);
  const [total, setTotal] = useState(0);
  const [requesting, setRequesting] = useState(false);
  const [keyword, setKeyword] = useState();
  const [deletedIds, setDeletedIds] = useState([]);
  const [param, setParam] = useSearchParams();
  const urlId = param.get("id");
  const isInit = useRef(true);

  const navigate = useNavigate();

  const afterRemoveHandler = ({ id }) => {
    setDeletedIds([...deletedIds, id]);
  };

  const afterRenameHandler = ({ id, title }) => {
    loadedList.forEach((item) => {
      if (item.id === id) {
        item.title = title;
      }
    });
  };

  useEffect(() => {
    setBulkSelected([]);
  }, [bulkStatus]);

  useEffect(() => {
    !jwt && mutate();
  }, [jwt]);

  useEffect(() => {
    urlId && getSessionInfoById();
    if (param.get("quickReply")) {
      dispatch.chat.update({
        quickReply: param.get("quickReply"),
        replyVersion: Date.now(),
        chatAtHome: false,
      });
    }
  }, []);

  // url携带id, 输入框placeholder
  useEffect(() => {
    dispatch.chat.update({
      placeHolderText: placeholderList[quickReply],
    });
  }, [placeholderList]);

  useEffect(() => {
    if (!bulkIIndeterminate) {
      setBulkSelected(bulkIsAll ? loadedList?.map((x) => x.id) : []);
    }
  }, [bulkIsAll, bulkIIndeterminate]);

  useEffect(() => {
    if (page === 1 && requesting) {
      return;
    }
    // setLoadedList([]);
    // setTotal(0);
    // 如果已经是第一页，则直接搜索
    if (page === 1 && !requesting && !isInit.current) {
      isInit.current = false;
      setRequesting(true);
      getSessionListByPage();
      return;
    }
    // 否则触发从第一页开始加载
    setPage(1);
  }, [
    // 刷新对话左侧列表的3种情况：
    // ① 绑定token/登陆等，用户信息发送变化
    // ② 列表搜索，用户输入的关键字发生变化，需要搜索过滤
    // ③ 最近一个会话ID变化（表示创建新会话了），需要刷新列表并显示最新会话在最前面
    // keyword,
    jwt,
    latestSessionId,
  ]);

  useEffect(() => {
    if (page === 1 && requesting) {
      return;
    }

    if ((page - 1) * pageSize <= total) {
      isInit.current = false;
      jwt && setRequesting(true);
      getSessionListByPage();
    } else {
      showMessage();
    }
  }, [page]);

  const {
    run: getSessionListByPage,
    loading,
    mutate,
  } = useRequest(
    () =>
      dataService.getSessionByPage({
        search: keyword,
        type: 0,
        page_size: pageSize,
        page,
      }),
    {
      manual: true,
      ready: !!jwt,
      cacheKey: "cacheKey-chat",
      cacheTime: -1,
      refreshDeps: [],
      debounceWait: 500,
      onSuccess: (res) => {
        setRequesting(false);
        if (res.data?.results?.length) {
          setTotal(res.data?.count || 0);
          setLoadedList(page === 1 ? [...res.data.results] : [...loadedList, ...res.data.results]);
        }
      },
      onError: (err) => {
        setRequesting(false);
        feedback(err.data);
      },
      onFinally: () => {
        setRequesting(false);
      },
    }
  );

  const onScrollFrame = ({ clientHeight, scrollTop, scrollHeight }) => {
    if (clientHeight + scrollTop > scrollHeight - 1 && !requesting) {
      setPage(page + 1);
    }
  };

  const showMessage = () => {
    const ptarget = document.querySelector(".chat-session-scrollbars");
    const element = ptarget?.querySelector("div:first-child");
    element.classList.add("scrolling-bottom");
  };

  // 路由携带ID获取详情
  const { run: getSessionInfoById } = useRequest(() => dataService.getSession(urlId), {
    manual: true,
    ready: !!urlId,
    onSuccess: (res) => {
      const { id, title, scenes } = res.data;
      dispatch.chat.update({
        sessionId: id,
        windowTitle: title,
        replyVersion: Date.now(),
        quickReply: scenes,
        chatAtHome: false,
      });
    },
  });

  // 新会话处理路由参数
  useEffect(() => {
    if (!sessionId) return;
    navigate(`/chat?id=${sessionId}`, { replace: true });
  }, [sessionId]);

  const bulkRemoveHandler = useCallback(async () => {
    try {
      const res = await dataService.bulkRemoveSession({ ids: bulkSelected });
      if (res.status === "OK") {
        message.success("操作成功");
        setBulkSelected([]);
        setDeletedIds([...deletedIds, ...bulkSelected]);
        if (sessionId && bulkSelected.includes(sessionId)) {
          dispatch.chat.resetSession();
          navigate("/chat");
        }
      }
    } catch (err) {
      feedback(err.data);
    }
  }, [bulkSelected]);

  const setBulkByItemHandler = ({ id }) => {
    setBulkStatus(true);
    setTimeout(() => {
      setBulkSelected([id]);
    }, 100);
  };

  const searchHandler = useCallback(
    (value) => {
      setKeyword(value);
      // 搜索
      setLoadedList([]);
      setTotal(0);
      setRequesting(true);
      page > 1 && setPage(1);
      getSessionListByPage();
    },
    [setKeyword, page]
  );

  const newSessionHandler = useCallback(() => {
    navigate("/chat");
    dispatch.chat.resetSession();
    setKeyword();
    if (!middle) {
      siderHandler(false);
    }
  }, [setKeyword, dispatch, siderHandler]);

  const items = loadedList
    ?.filter((x) => !deletedIds.includes(x.id))
    ?.map((session) => (
      <ChatSessionItem
        key={session.id}
        session={session}
        currentSession={sessionId}
        siderHandler={siderHandler}
        afterRename={afterRenameHandler}
        afterRemove={afterRemoveHandler}
        bulkStatus={bulkStatus}
        bulkSelected={bulkSelected}
        updateBulkSelected={(selected) => setBulkSelected([...selected])}
        setBulkByItemHandler={setBulkByItemHandler}
      />
    ));

  return (
    <div className={cls(styles.sessionList, "sessionList")}>
      <div className="session-list-hd">
        <div className="search-and-create">
          <ActionIcons type="search" />
          <Input.Search
            placeholder={"搜索问题"}
            allowClear
            onChange={(e) => searchHandler(e.target.value)}
            value={keyword}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            trigger={"click"}
            onClick={newSessionHandler}
            className="create-session-btn"
          >
            新建会话
          </Button>
        </div>
      </div>
      <div className="session-list-bd">
        {loading && !items.length ? (
          <div className="spin">
            <Spin indicator={<LoadingOutlined />} />
          </div>
        ) : (
          <Fragment>
            {bulkStatus ? (
              <ChatSessionBulk
                total={total || 0}
                bulkSelected={bulkSelected}
                updateBulkAllHandler={({ checked, indeterminate }) => {
                  setBulkIsAll(checked);
                  setBulkIIndeterminate(indeterminate);
                }}
                bulkCancelHandler={() => setBulkStatus(false)}
                bulkRemoveHandler={bulkRemoveHandler}
              />
            ) : (
              <ChatSessionStatistics
                count={total || 0}
                bulkStartHandler={() => setBulkStatus(true)}
              />
            )}
            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={{ height: "calc(100% - 40px)" }}
              onScrollFrame={onScrollFrame}
              className="chat-session-scrollbars"
            >
              {isEmpty(items) ? <Empty description={"请新建会话"} /> : items}
              {loading && items.length > 0 ? (
                <div className="chat-session-loading">
                  <Spin indicator={<LoadingOutlined />}></Spin>
                </div>
              ) : null}
            </Scrollbars>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default SessionList;
