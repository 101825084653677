import ChatStreamEventHandler from "./handlers/ChatStreamEventHandler";
import DocUploadEventHandler from "./handlers/DocUploadEventHandler";
import PaymentEventHandler from "./handlers/PaymentEventHandler";
class EventSourceFactory {
  constructor(store) {
    this._url = `https://stream.chat.phitrellis.com/api/chat/${store.getState().token.visitorId}/`;
    this._onMessageHandler = this.onMessage.bind(this);
    this._onFailedHandler = this.onFailed.bind(this);
    this._es = new window.ReconnectingEventSource(`${this._url}`);
    this._es.addEventListener("message", this._onMessageHandler, false);

    // 适配器模式，注册消息handler
    // 根据返回message上的business业务类型字段，分发到不同的handler处理
    this._handlerMap = new Map();
    this._handlerMap.set("data", new ChatStreamEventHandler(store));
    this._handlerMap.set("doc", new DocUploadEventHandler(store));
    this._handlerMap.set("pay", new PaymentEventHandler(store));
  }

  onMessage(event) {
    if (process.env.NODE_ENV === "development") {
      console.info(JSON.parse(event.data));
    }
    if (!event || !event.data) {
      this.onFailed("请求出错，请重试");
      return;
    }
    let message;
    try {
      message = JSON.parse(event.data);
    } catch (e) {
      console.error(e);
    }
    if (!message) {
      this.onFailed("请求返回不正确，请重试");
    }

    const { business } = message;
    const handler = this._handlerMap.get(business);
    if (!handler) {
      return;
    }
    handler.onMessage(message);
  }

  onFailed(err) {
    console.error(err);
  }

  close() {
    this._es.close();
  }
}

export default EventSourceFactory;
