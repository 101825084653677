import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { message, Input, Button } from "antd";
import { BarsOutlined, SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useRequest } from "ahooks";
import { last } from "lodash-es";
import useScreenSize from "@/hooks/useScreenSize";
import { data as dataService } from "@/services";
import { feedback } from "@/utils/index";
import ClearButton from "./ClearButton";
import styles from "./index.module.less";

const { Search } = Input;

const titles = {
  "/chat": "新会话",
  "/document": "新文献",
};

function EditableTitle({ siderHandler }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { middle } = useScreenSize();
  const saveBtnRef = useRef();
  const { pathname } = useLocation();

  const {
    windowTitle: chatTitle,
    sessionId: chatId,
    quickReply: chatTitlePrefix,
    chatAtHome,
  } = useSelector((state) => state.chat);
  const {
    windowTitle: documentTitle,
    sessionType,
    sessionId: documentId,
    paths,
    documentAtHome,
  } = useSelector((state) => state.document);

  const [titleEditing, setTitleEditing] = useState(false);
  const [value, setValue] = useState(titles[pathname]);
  const [sessionId, setSessionId] = useState();

  useEffect(() => {
    switch (pathname) {
      case "/chat":
        setValue(chatTitle || titles[pathname]);
        setSessionId(chatId);
        break;
      case "/document":
        setValue(documentTitle || titles[pathname]);
        setSessionId(documentId || last(paths));
        break;
      default:
        break;
    }
    setTitleEditing(false);
  }, [pathname, chatId, documentId, paths, chatTitle, documentTitle]);

  const titleChangeHandler = useCallback(
    (e) => {
      setValue(e.target.value);
    },
    [setValue]
  );

  const editorHandler = useCallback(() => {
    setTitleEditing(!!sessionId);
  }, [sessionId]);

  //失焦时取消修改并返回原样
  const blurHandler = useCallback(
    (e) => {
      let windowTitle;
      switch (pathname) {
        case "/chat":
          windowTitle = chatTitle;
          break;
        case "/document":
          windowTitle = documentTitle;
          break;
        default:
          break;
      }
      setTitleEditing(false);
      setValue(windowTitle);
    },
    [chatTitle, documentTitle, titleEditing, pathname]
  );

  const goBackHandler = useCallback(
    async (to) => {
      switch (pathname) {
        case "/chat":
          navigate(to, { replace: true, show: true });
          dispatch.chat.resetSession({ windowTitle: "新会话" });
          break;
        case "/document":
          navigate(to, { replace: true, show: true });
          dispatch.document.resetSession();
          break;
        default:
          break;
      }
      dispatch.user.resetCheck();
    },
    [dispatch, pathname]
  );

  const goExitPdfHandler = useCallback(() => {
    siderHandler(true);
    last(paths) ? navigate(`/document?id=${last(paths)}`) : navigate("/document");
    dispatch.document.update({
      sessionId: last(paths),
      file: null,
      sessionType: 2,
      paperInfo: {},
      windowTitle: last(paths) ? "" : "新文献",
    });
  }, [paths]);

  const { run: saveHandler } = useRequest(
    () =>
      dataService.updateSession(sessionId, {
        title: value,
      }),
    {
      ready: !!value,
      manual: true,
      onSuccess: (res) => {
        message.success("标题保存成功！");
        dispatch[pathname.replace("/", "")].update({
          windowTitle: res.data.title,
          version: Date.now(),
        });
      },
      onError: (err) => {
        feedback(err.data);
      },
      onFinally: () => {
        setTitleEditing(false);
      },
    }
  );

  return (
    <div className={styles.windowTitle}>
      <div className="title-container">
        {!middle && (
          <Button
            type="text"
            size="large"
            icon={<BarsOutlined />}
            onClick={() => siderHandler(true)}
          />
        )}
        {titleEditing ? (
          <Search
            autoFocus={true}
            bordered={false}
            value={value}
            onBlur={blurHandler}
            onChange={titleChangeHandler}
            onSearch={saveHandler}
            title={value}
            placeholder="请输入标题"
            enterButton={
              <Button
                ref={saveBtnRef}
                size="small"
                icon={<SaveOutlined />}
                onClick={saveHandler}
              >
                {middle && "保存"}
              </Button>
            }
          />
        ) : (
          <div
            className="title-text"
            onClick={editorHandler}
          >
            {(pathname === "/chat" && !chatAtHome) ||
            (pathname === "/document" && !documentAtHome && !documentTitle) ? (
              <ArrowLeftOutlined
                className="title-back-icon"
                onClick={() => goBackHandler("/chat")}
              />
            ) : (
              ""
            )}
            {!!documentTitle && sessionType === 1 ? (
              <ArrowLeftOutlined
                className="title-back-icon"
                onClick={() => goExitPdfHandler()}
              />
            ) : (
              ""
            )}
            <span>
              {chatTitlePrefix || ""}
              {chatTitlePrefix ? " / " : ""}
              {!chatAtHome && value}
            </span>
          </div>
        )}
        {
          <ClearButton
            sessionId={sessionId}
            pathname={pathname}
          />
        }
      </div>
    </div>
  );
}

export default EditableTitle;
