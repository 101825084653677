export const document = {
  state: {
    sessionId: undefined,
    lastMessageId: undefined,
    folderId: undefined,
    version: Date.now(),
    windowTitle: undefined,
    paperInfo: {},
    file: undefined, //check if pdfviewer is open
    paths: [],
    pathnames: [],
    sessionType: 1,
    documentAtHome: true,
  },
  reducers: {
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    // eslint-disable-next-line no-unused-vars
    resetSession(state, payload) {
      return {
        ...state,
        sessionId: undefined,
        folderId: undefined,
        lastMessageId: undefined,
        version: Date.now(),
        windowTitle: undefined,
        paperInfo: {},
        file: undefined,
        paths: [],
        pathnames: [],
        sessionType: 1,
        documentAtHome: true,
      };
    },
    // eslint-disable-next-line no-unused-vars
    version(state, payload) {
      return { ...state, version: Date.now() };
    },
    uploadLastMessageId(state, payload) {
      return { ...state, lastMessageId: payload };
    },
  },
  // eslint-disable-next-line no-unused-vars
  effects: (dispatch) => ({}),
};
