import { useSelector } from "react-redux";
import cls from "classnames";
import styles from "./index.module.less";

const EditorInput = ({ children, invalid, tagType, required, label, value, onChange, placeholder }) => {
  const { isFirefox } = useSelector((state) => state.browser);

  const CustomTag = tagType;

  return (
    <div className={styles.editorInputWrapper}>
      <div className={cls(tagType, "editorInput", { invalid })}>
        {required ? <i className="required">*</i> : null}
        <span className={cls("label")}>{label}</span>
        <CustomTag
          data-placeholder={placeholder}
          contentEditable={isFirefox ? true : "plaintext-only"}
          onBlur={(event) => onChange({ text: (event.target.innerText || "").replace(/(\r\n|\n|\r)/gm, "") })}
          dangerouslySetInnerHTML={{ __html: children || value }}
        />
      </div>
    </div>
  );
};

export default EditorInput;
