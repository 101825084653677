import { useEffect, useState, Fragment, useRef, forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import styles from "./index.module.less";
import Scrollbars from "react-custom-scrollbars-2";
import cls from "clsx";
import { Avatar, Tooltip } from "antd";
import MessageContentRender from "@/components/common/MessageContentRender";
import { getModelName } from "@/constants/model";
import ScrollToBottom from "@/components/common/ScrollToBottom";
import ScrollToTop from "@/components/common/ScrollToTop";
import useScreenSize from "@/hooks/useScreenSize";
import { useThrottleEffect } from "ahooks";
import { throttle } from "lodash-es";

const ChatBody = forwardRef((props, ref) => {
  const { middle } = useScreenSize();
  let { messageList, setText, inputRef, onOutlineSend, setMessageList, onRefineSend } = props;
  const chatBodyScroll = useRef(null);
  const { pluginPlaceHolder, inputHeight } = useSelector((state) => state.chat);
  // 其他类型message
  const systemType = ["message-loading", "plugin-placeholder"];

  // 滚动到底部
  useEffect(() => {
    setTimeout(() => {
      if (chatBodyScroll.current) chatBodyScroll.current?.scrollToBottom("smooth");
    }, 1000);
  }, [messageList]);

  // 流式输出的过程中，自动滚动到底部
  const onMessageTyping = throttle(() => {
    if (chatBodyScroll.current) chatBodyScroll.current?.scrollToBottom("smooth");
  }, 1000);

  useImperativeHandle(ref, () => ({}));

  return (
    <div className={styles.MessageContainer}>
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        style={{ height: `calc(100% - ${inputHeight}px)` }}
        className="message-scrollbar"
        ref={chatBodyScroll}
      >
        <div className="messageList">
          {messageList.length > 0
            ? messageList.map((msg, index) => {
                return msg.content.message_type && systemType.includes(msg.content.message_type) ? (
                  <div
                    className={cls("systemMessage", {
                      "plugin-placeholder": pluginPlaceHolder,
                    })}
                    key={index}
                  >
                    <MessageContentRender
                      msg={msg}
                      setText={setText}
                      onMessageTyping={onMessageTyping}
                      onOutlineSend={onOutlineSend}
                      messageList={messageList}
                      setMessageList={setMessageList}
                      onRefineSend={onRefineSend}
                    />
                  </div>
                ) : (
                  // data-id用于删除功能 Message left right用于删除样式修改
                  <div
                    className={cls({
                      "messageLeftWrapper Message left": msg.position === "left",
                      "messageRightWrapper Message right": msg.position === "right",
                    })}
                    style={{ flexDirection: middle ? "row" : "column" }}
                    data-id={`${msg.content.messageId}-${msg.position === "left" ? "answer" : "question"}`}
                    key={index}
                  >
                    {msg.user.avatar && msg.position === "right" && (
                      <div
                        className="avatar"
                        style={{ margin: middle ? "0 10px" : "10px 0" }}
                      >
                        <Avatar
                          size={36}
                          src={msg.user.avatar}
                        ></Avatar>
                      </div>
                    )}
                    {msg.user.avatar && msg.position === "left" && (
                      <div
                        className="avatar"
                        style={{ margin: middle ? "0 10px" : "10px 0" }}
                      >
                        <Tooltip
                          title={getModelName(msg.content.model)}
                          placement="topLeft"
                          arrowPointAtCenter
                        >
                          <Avatar
                            size={36}
                            src={msg.user.avatar}
                          ></Avatar>
                        </Tooltip>
                      </div>
                    )}
                    <MessageContentRender
                      msg={msg}
                      setText={setText}
                      onMessageTyping={onMessageTyping}
                      onOutlineSend={onOutlineSend}
                      messageList={messageList}
                      setMessageList={setMessageList}
                      onRefineSend={onRefineSend}
                    />
                  </div>
                );
              })
            : null}
        </div>
      </Scrollbars>
      {chatBodyScroll.current && <ScrollToTop />}
      {chatBodyScroll.current && <ScrollToBottom />}
    </div>
  );
});

ChatBody.displayName = "ChatBody";
export default ChatBody;
