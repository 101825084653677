import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Descriptions, Dropdown, Space, Typography } from "antd";
import { FilePdfOutlined, LoadingOutlined, SendOutlined } from "@ant-design/icons";
import cls from "classnames";
import useScreenSize from "@/hooks/useScreenSize";

const { Paragraph, Text } = Typography;

function ArxivCard({ props, index }) {
  const { title, published, authors, pdf_url, summary, doi, url, updated, primary_category } = props;
  const { middle } = useScreenSize();
  const { model } = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);

  const linkHandler = useCallback((url) => {
    window.open(url, "_blank");
  }, []);

  const addHandler = useCallback(() => {
    setUploading(true);
    const results = dispatch.chat.addScholar({
      pdf_url,
      title,
      model,
      info: props,
    });
    results.then((res) => setUploading(res));
  }, [dispatch, props, pdf_url, title, setUploading, model]);

  return (
    <div className={cls("arxivCard", { widthStyle: !middle })}>
      <Descriptions
        column={2}
        title={
          <Paragraph
            className="title"
            ellipsis={{
              rows: 1,
              expandable: true,
              symbol: "更多",
              onExpand: (e) => e.stopPropagation(),
            }}
            title={`${index}. ${title}`}
            onClick={() => linkHandler(url)}
          >
            {index}. {title}
          </Paragraph>
        }
        extra={
          pdf_url && (
            <Dropdown.Button
              menu={{
                items: [
                  {
                    label: (
                      <Button
                        className={cls({ disabled: uploading })}
                        type="text"
                        icon={uploading ? <LoadingOutlined /> : <SendOutlined />}
                        onClick={addHandler}
                      >
                        导入文献分析
                      </Button>
                    ),
                    key: "1",
                  },
                ],
              }}
              getPopupContainer={(node) => node}
              onClick={() => linkHandler(pdf_url)}
            >
              <FilePdfOutlined /> 打开PDF
            </Dropdown.Button>
          )
        }
      />
      <div className="intruduce">
        <Text
          className="auth"
          title={authors}
        >
          {authors}
        </Text>
        <Text className="publish">{published}</Text>
      </div>
      <Paragraph
        className="content"
        ellipsis={{
          rows: 3,
          expandable: true,
          symbol: "more",
        }}
        title={summary}
      >
        {summary}
      </Paragraph>
    </div>
  );
}

export default ArxivCard;
