import { useCallback } from "react";
import { Button, message, Modal } from "antd";
import ToolsIcons from "@/icons/ToolsIcons";
import "./index.module.less";
import { data as dataService } from "@/services";
import { useRequest } from "ahooks";
import { feedback } from "@/utils/index";
import { ExclamationCircleOutlined } from "@ant-design/icons";

function PhraseItemAction({ item, setOpen, setType, setEditData, getList }) {
  const handlerEdit = useCallback((e) => {
    setOpen(true);
    setType("edit");
    setEditData(item);
    e.preventDefault();
    e.stopPropagation();
  });

  const handlerDelete = useCallback(async (id) => {
    const res = await dataService.deleteInstruction(id);
    if (res.status === "OK") {
      message.success("删除成功");
      setOpen(false);
      getList();
    } else {
      feedback(res.data);
    }
  }, []);

  return (
    <div className="PhraseItemAction">
      <Button
        type="link"
        icon={<ToolsIcons type="phraseEdit" />}
        onClick={handlerEdit}
      ></Button>
      <Button
        type="link"
        icon={<ToolsIcons type="phraseDelete" />}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          Modal.confirm({
            title: "删除常用语",
            icon: <ExclamationCircleOutlined />,
            content: "确认删除吗？",
            onOk: () => handlerDelete(item.id),
            okText: "确认",
            cancelText: "取消",
            style: { top: "20%" },
          });
        }}
      ></Button>
    </div>
  );
}

export default PhraseItemAction;
