import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { CommentOutlined, FilePdfOutlined, UserOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./index.module.less";
import classNames from "classnames";

function BottomMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const navigateHandler = useCallback(
    async (to) => {
      switch (pathname) {
        case "/chat":
          navigate(to, { replace: true, show: true });
          dispatch.chat.resetSession();
          break;
        case "/document":
          navigate(to, { replace: true, show: true });
          dispatch.document.resetSession();
          break;
        default:
          break;
      }
      dispatch.user.resetCheck();
    },
    [dispatch, pathname]
  );

  return (
    <div className={styles.BottomMenu}>
      <div className={classNames("bottomMenu-container")}>
        <div
          onClick={() => {
            navigateHandler("/chat");
            dispatch.chat.update({
              chatAtHome: true,
            });
          }}
          className={classNames("bottomMenu-item", { active: pathname === "/chat" })}
        >
          <CommentOutlined />
          <span>对话</span>
        </div>
        <div
          onClick={() => navigateHandler("/document")}
          className={classNames("bottomMenu-item", { active: pathname === "/document" })}
        >
          <FilePdfOutlined />
          <span>文献</span>
        </div>
        <a href="/console/">
          <div className={classNames("bottomMenu-item")}>
            <UserOutlined />
            <span>我的</span>
          </div>
        </a>
      </div>
    </div>
  );
}

export default BottomMenu;
