import { createPortal } from "react-dom";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { CloseOutlined, ArrowLeftOutlined } from "@ant-design/icons";

import { Tooltip } from "antd";
import Draggable from "react-draggable"; // The default
import cls from "classnames";
import FloatSiderIcons from "@/icons/FloatSiderIcons";
import styles from "./index.module.less";
import "./style.less";

const FloatSider = ({ quickReply, tutorUrl, faqUrl }) => {
  let mousedownTimestamp;
  let mousedownY;
  const height = 210;
  const headerHeight = 64;
  const cachePositionKey = "sider-tutor-position";
  const cacheStatusKey = "sider-tutor-status";
  const trackHeight = document.querySelector("#root").getBoundingClientRect().height;
  const ref = useRef(null);
  const lastPosition = Number(localStorage.getItem(cachePositionKey)) ?? (trackHeight - height) / 2 - 50;

  const [showSider, setShowSider] = useState(Number(localStorage.getItem(cacheStatusKey)) === 1);
  const [collapsed, setCollapsed] = useState(Number(localStorage.getItem(cacheStatusKey)) === 0);

  const onClick = useCallback(
    (action) => {
      switch (action) {
        case "tutor":
          window.open(tutorUrl || "https://www.yuque.com/bi_wk/muac4q", "_blank");
          break;
        case "faq":
          window.open(faqUrl || "https://www.yuque.com/bi_wk/muac4q/enzxrsxglt77metp", "_blank");
          break;
        case "userservice":
          window.docGptOpenServiceModal();
          break;
      }
    },
    [quickReply]
  );

  const onToggle = useCallback(() => {
    setShowSider(!showSider);
    localStorage.setItem(cacheStatusKey, !showSider ? 1 : 0);
    setTimeout(() => {
      setCollapsed(showSider);
    }, 800);
  }, [showSider]);

  return createPortal(
    <Draggable
      axis="y"
      allowAnyClick={true}
      onStart={(e) => {
        mousedownY = ref.current.getBoundingClientRect().y;
        mousedownTimestamp = Date.now();
      }}
      onStop={(e, data) => {
        localStorage.setItem(cachePositionKey, data.y);
        const type = e.target.getAttribute("data-item-id");
        // 仅当鼠标按下弹起时间小于1000ms，且y轴方向移动小于10px，认为是点击
        if (
          type &&
          Date.now() - mousedownTimestamp < 1000 &&
          Math.abs(ref.current.getBoundingClientRect().y - mousedownY) < 10
        ) {
          onClick(type);
        }
        mousedownTimestamp = 0;
      }}
      defaultPosition={{ x: 0, y: lastPosition }}
      bounds={{ top: 0, bottom: trackHeight - height - headerHeight }}
    >
      <div
        className={cls(styles.FloatSider, "floatSider", { close: !showSider, collapsed })}
        ref={ref}
      >
        <div
          className="float-sider-handle"
          onClick={onToggle}
        >
          {showSider ? (
            <CloseOutlined />
          ) : (
            <div className="float-sider-mini">
              <span>教</span>
              <span>程</span>
              <ArrowLeftOutlined />
            </div>
          )}
        </div>
        <div className="float-sider-container">
          <Tooltip
            title="插件教程"
            placement="left"
          >
            <div
              className="float-sider-item"
              data-item-id="tutor"
            >
              <span className="icon">
                <FloatSiderIcons type="tutor" />
              </span>
              <span className="icon">教程</span>
            </div>
          </Tooltip>
          <Tooltip
            title="常见问题"
            placement="left"
          >
            <div
              className="float-sider-item"
              data-item-id="faq"
            >
              <span className="icon">
                <FloatSiderIcons type="faq" />
              </span>
              <span className="icon">FAQ</span>
            </div>
          </Tooltip>
          <Tooltip
            title="联系我们"
            placement="left"
          >
            <div
              className="float-sider-item"
              data-item-id="userservice"
            >
              <span className="icon">
                <FloatSiderIcons type="userservice" />
              </span>
              <span className="icon">客服</span>
            </div>
          </Tooltip>
        </div>
      </div>
    </Draggable>,
    document.querySelector("#root")
  );
};

export default FloatSider;
