import { Alert, Button, Space, message } from "antd";
import { useCallback, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Marquee from "react-fast-marquee";
import { data as dataService } from "@/services";
import { useRequest } from "ahooks";

function Noticebar({ messageList }) {
  const dispatch = useDispatch();
  let selectedList = messageList.map((v) => {
    return v.id;
  });

  const closeHandler = useCallback(() => {
    handlerBatchCheck();
  }, [dispatch]);

  const { run: handlerBatchCheck } = useRequest(() => dataService.batchChecked({ ids: selectedList }), {
    manual: true,
    onSuccess: (res) => {
      dispatch.user.update({ refeashMessage: true });
    },
    onError: (err) => {
      message.error(err.data);
    },
  });

  return (
    <Alert
      type="warning"
      banner
      message={
        <Marquee
          gradient={false}
          speed={50}
          pauseOnHover={true}
        >
          {messageList.map((item, index) => {
            return (
              <Fragment key={item.id}>
                <b>{item.title}:</b>
                <span>{item.content}</span>
                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
              </Fragment>
            );
          })}
        </Marquee>
      }
      closable
      onClose={closeHandler}
    />
  );
}

export default Noticebar;
