import { Affix, Checkbox } from "antd";
import styles from "./index.module.less";
import { useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";

function EvidenceSelectMenu() {
  const dispatch = useDispatch();
  const options = [
    { label: "Systematic Review & Meta-Analysis", value: "Systematic Review & Meta-Analysis" },
    { label: "Randomized Controlled Trial", value: "Randomized Controlled Trial" },
    { label: "Cohort Studies", value: "Cohort Studies" },
    { label: "Case-Control Studies", value: "Case-Control Studies" },
    { label: "Observational Studies", value: "Observational Studies" },
  ];

  useEffect(() => {
    dispatch.config.update({
      pubmedTypes: ["Randomized Controlled Trial"],
    });
  }, []);

  const onChange = useCallback((checkedValues) => {
    dispatch.config.update({
      pubmedTypes: checkedValues,
    });
  });

  return (
    <Affix style={{ position: "absolute", top: 170, right: 12 }}>
      <div className={styles.evidenceSelectMenu}>
        <span className="menuTitle">PubMed分类</span>
        <Checkbox.Group
          options={options}
          defaultValue={["Randomized Controlled Trial"]}
          onChange={onChange}
        />
      </div>
    </Affix>
  );
}
export default EvidenceSelectMenu;
