import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styles from "./index.module.less";
import { Avatar, Image, Button, Space, message, Tooltip } from "antd";
import {
  SettingOutlined,
  QuestionCircleOutlined,
  FileSyncOutlined,
  LogoutOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import ThingIcons from "@/icons/ThingIcons";
import copy from "copy-to-clipboard";

/* 
  点卡用户 0
  月卡用户 4
  畅享月卡 7
  高级月卡 8
  企业用户 1
*/
function UserPopContent({ avatar, setOpen, tokenInfo }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dark } = useSelector((state) => state.theme);
  const { username, user, user_type_number, user_type, left_count, end_time, gpt4, o1 } = tokenInfo;
  const benefits = {
    // 免费用户 -1
    "-1": [
      {
        description: "4o mini /文心一言:",
        use: " 免费用",
        icon: "free",
      },
      {
        description: "Claude Haiku/Sonnet/Opus:",
        use: " 按次扣",
        icon: "points",
      },
      {
        description: "GPT 4o/OpenAI o1:",
        use: " 按次扣",
        icon: "points",
        showBtn: true,
        btnName: "购月卡享更多",
      },
    ],
    //点卡用户 0
    0: [
      {
        description: "4o mini /文心一言:",
        use: " 免费用",
        icon: "free",
      },
      {
        description: "Claude Haiku/Sonnet/Opus:",
        use: " 按次扣",
        icon: "points",
      },
      {
        description: "GPT 4o/OpenAI o1:",
        use: " 按次扣",
        icon: "points",
        showBtn: true,
        btnName: "购月卡享更多",
      },
    ],
    //月卡用户 4
    4: [
      {
        description: "4o mini /文心一言:",
        use: " 免费用",
        icon: "free",
      },
      {
        description: "Claude Haiku/Sonnet/Opus/GPT 4o:",
        use: " 无限用",
        icon: "unlimited",
      },
      {
        description: "GPT 4o:",
        use: ` 已用${gpt4.usage}次/共${gpt4.total}次`,
        icon: "number",
        showBtn: true,
        btnName: gpt4.usage < gpt4.total ? "升级高级月卡" : "充值点数",
      },
      {
        description: "OpenAI o1:",
        icon: "upgrade", // 使用该字段判断是否需要升级，影响样式和升级解锁按钮显示
        showBtn: true,
        btnName: "升级解锁",
      },
    ],
    //畅享月卡 7
    7: [
      {
        description: "4o mini /文心一言:",
        use: " 免费用",
        icon: "free",
      },
      {
        description: "Claude Haiku:",
        use: " 无限用",
        icon: "unlimited",
      },
      {
        description: "Sonnet/Opus/GPT 4o/o1:",
        icon: "upgrade", // 使用该字段判断是否需要升级，影响样式
        showBtn: true,
        btnName: "升级解锁",
      },
    ],
    //高级月卡 8
    8: [
      {
        description: "4o mini /文心一言:",
        use: " 免费用",
        icon: "free",
      },
      {
        description: "Claude Haiku/Sonnet/Opus/GPT 4o:",
        use: " 无限用",
        icon: "unlimited",
      },
      {
        description: "OpenAI o1:",
        use: ` 已用${o1.usage}次/共${o1.total}次`,
        icon: "number",
        showBtn: o1.usage >= o1.total,
        btnName: "充值点数",
      },
    ],
    // 9
    9: [
      {
        description: "4o mini /文心一言:",
        use: " 免费用",
        icon: "free",
      },
      {
        description: "Claude Haiku/Sonnet/",
        use: " 无限用",
        icon: "unlimited",
      },
      {
        description: "Opus/GPT 4o/o1:",
        icon: "upgrade", // 使用该字段判断是否需要升级，影响样式
        showBtn: true,
        btnName: "升级解锁",
      },
    ],
    // 企业用户 1
    1: [
      {
        description: "4o mini /文心一言:",
        use: " 免费用",
        icon: "free",
      },
      {
        description: "Claude Haiku/Sonnet/Opus:",
        use: " 按次扣",
        icon: "points",
      },
      {
        description: "GPT 4o/OpenAI o1:",
        use: " 按次扣",
        icon: "points",
      },
    ],
  };

  const copyHandler = useCallback((text) => {
    if (copy(text)) {
      message.success("内容已复制到剪切板");
    } else {
      message.error("无法复制内容，请确认浏览器是否支持或手动复制");
    }
  });

  return (
    <div className={styles.UserPopContent}>
      <div className="pop-content-info">
        <Avatar
          className="user-avatar"
          icon={
            <Image
              src={avatar}
              preview={false}
            />
          }
        />
        <div className="user-info">
          <span className="user-name">用户名: {username}</span>
          <div className="user-id">
            <span className="user-id-text">ID: {user}</span>
            <Tooltip title="复制">
              <CopyOutlined onClick={() => copyHandler(user)} />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="pop-content-member">
        <div className="current-member">
          <div className="member-points">
            <Space>
              <span className="member-type">{user_type}</span>
              {user_type_number === 0 || user_type_number === 1 ? (
                <span className="member-points-count">
                  <ThingIcons type="coin" />
                  {left_count}点
                </span>
              ) : end_time ? (
                <span className="member-end-time">{/\d{4}-\d{1,2}-\d{1,2}/g.exec(end_time)}到期</span>
              ) : null}
            </Space>
          </div>
          <div
            className="member-btn"
            onClick={() => {
              window.open("/console/", "_blank");
            }}
          >
            查看会员权益
          </div>
        </div>
        <div className="member-benefits">
          {benefits[user_type_number]?.map((item, index) => {
            return (
              <div
                className="benefit-item"
                key={`${index}-${user_type_number}`}
                style={
                  item.icon === "upgrade"
                    ? { color: "rgba(166, 166, 166, 1)" }
                    : dark
                      ? { color: "rgba(255,255,255,1)" }
                      : { color: "rgba(0, 0, 0, 1)" }
                }
              >
                <div className="item-info">
                  <ThingIcons type={item.icon} />
                  <span className="item-desc">{item.description}</span>
                  <span className="item-use">{item.use}</span>
                </div>
                <div className="item-btn">
                  {item.showBtn && (
                    <Button
                      type="link"
                      size="small"
                      style={{ fontSize: "12px" }}
                      className="upgrade-btn"
                      href="/console/vip"
                    >
                      {item.btnName}
                    </Button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="pop-content-btn">
        <div
          className="btn-item"
          onClick={() => {
            window.open("/console/", "_blank");
          }}
        >
          <SettingOutlined />
          <div className="name">个人中心</div>
        </div>
        <div className="line"></div>
        <div
          className="btn-item"
          onClick={() => {
            window.open("https://www.yuque.com/bi_wk/muac4q/kpkszqvvtkk01ao3", "_blank");
          }}
        >
          <FileSyncOutlined />
          <div className="name">更新日志</div>
        </div>
        <div className="line"></div>
        <div
          className="btn-item"
          onClick={() => {
            window.open("https://www.yuque.com/bi_wk/muac4q", "_blank");
          }}
        >
          <QuestionCircleOutlined />
          <div className="name">帮助中心</div>
        </div>
        <div className="line"></div>
        <div
          className="btn-item"
          onClick={() => {
            if (!tokenInfo.openid) {
              setOpen(true);
            } else {
              message.success("您已成功退出");
              navigate("/home", { replace: true });
              dispatch.token.init({ hadLogin: false, hadOpenId: false });
              dispatch.user.resetSession(); //清除tokenInfo
              dispatch.chat.resetSession();
              dispatch.document.resetSession();
              localStorage.removeItem("tabActiveKey");
            }
          }}
        >
          <LogoutOutlined />
          <div className="name">退出登录</div>
        </div>
      </div>
    </div>
  );
}

export default UserPopContent;
