export const tutor = (
  <svg
    className="tutor"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M853.317818 113.757091c31.464727 0 56.925091 25.460364 56.925091 56.925091v512c0 31.464727-25.460364 56.878545-56.925091 56.878545h-312.878545v113.757091h170.682182a28.439273 28.439273 0 0 1 0 56.925091H312.878545a28.439273 28.439273 0 0 1 0-56.925091h170.682182V739.607273H170.682182a56.832 56.832 0 0 1-56.925091-56.878546v-512c0-31.464727 25.460364-56.925091 56.925091-56.925091h682.635636zM426.682182 284.439273a28.439273 28.439273 0 0 0-28.439273 28.439272v192.41891a28.439273 28.439273 0 0 0 41.146182 25.413818l192.372364-96.162909a28.439273 28.439273 0 0 0 0-50.920728L439.389091 287.464727a28.439273 28.439273 0 0 0-12.706909-3.025454z"
      fill="currentColor"
    ></path>
  </svg>
);

export const faq = (
  <svg
    className="faq"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M811.7 798c71.5-74.4 115.6-175.1 115.6-286.4 0-228.8-185.8-414.3-415-414.3s-415 185.4-415 414.3c0 226.6 182.3 410.4 408.4 414l-0.3 0.3H803c0.3-0.1 61.9 0.8 86.6 1.2 4.3 0.1 6.9-4.7 4.5-8.2-15.6-23.4-60.1-90.4-82.4-120.9z m-295.6-16.8c-33.9 0-59.8-23.2-59.8-58.4s25.9-57.8 59.8-57.8 60.4 22.6 60.4 57.8c0 35.1-26.5 58.4-60.4 58.4z m33.2-169.4h-67.7c-18-109.6 111.5-137.5 111.5-209.8 0-39.8-24.6-67.7-73-67.7-35.2 0-66.4 16.6-96.3 49.1L380 342.9c38.5-42.5 85.7-71.7 148.7-71.7 82.3 0 138.1 45.8 138.1 124.8 0 100.9-131.4 118.2-117.5 215.8z"
      fill="currentColor"
    ></path>
  </svg>
);

export const userservice = (
  <svg
    className="userservice"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M894.1 355.6h-1.7C853 177.6 687.6 51.4 498.1 54.9S148.2 190.5 115.9 369.7c-35.2 5.6-61.1 36-61.1 71.7v143.4c0.9 40.4 34.3 72.5 74.7 71.7 21.7-0.3 42.2-10 56-26.7 33.6 84.5 99.9 152 183.8 187 1.1-2 2.3-3.9 3.7-5.7 0.9-1.5 2.4-2.6 4.1-3 1.3 0 2.5 0.5 3.6 1.2a318.46 318.46 0 0 1-105.3-187.1c-5.1-44.4 24.1-85.4 67.6-95.2 64.3-11.7 128.1-24.7 192.4-35.9 37.9-5.3 70.4-29.8 85.7-64.9 6.8-15.9 11-32.8 12.5-50 0.5-3.1 2.9-5.6 5.9-6.2 3.1-0.7 6.4 0.5 8.2 3l1.7-1.1c25.4 35.9 74.7 114.4 82.7 197.2 8.2 94.8 3.7 160-71.4 226.5-1.1 1.1-1.7 2.6-1.7 4.1 0.1 2 1.1 3.8 2.8 4.8h4.8l3.2-1.8c75.6-40.4 132.8-108.2 159.9-189.5 11.4 16.1 28.5 27.1 47.8 30.8C846 783.9 716.9 871.6 557.2 884.9c-12-28.6-42.5-44.8-72.9-38.6-33.6 5.4-56.6 37-51.2 70.6 4.4 27.6 26.8 48.8 54.5 51.6 30.6 4.6 60.3-13 70.8-42.2 184.9-14.5 333.2-120.8 364.2-286.9 27.8-10.8 46.3-37.4 46.6-67.2V428.7c-0.1-19.5-8.1-38.2-22.3-51.6-14.5-13.8-33.8-21.4-53.8-21.3l1-0.2zM825.9 397c-71.1-176.9-272.1-262.7-449-191.7-86.8 34.9-155.7 103.4-191 190-2.5-2.8-5.2-5.4-8-7.9 25.3-154.6 163.8-268.6 326.8-269.2s302.3 112.6 328.7 267c-2.9 3.8-5.4 7.7-7.5 11.8z"
      fill="currentColor"
    ></path>
  </svg>
);
