class PaymentEventHandler {
  constructor(store) {
    this._store = store;
  }

  onMessage(message) {
    const { id, type, value } = message;
    if (type !== "end") {
      return;
    }
    this._store.dispatch.eventsource.updatePayment({
      ...this._store.getState().eventsource.paymentMap[id],
      ...value,
      id,
    });
  }
}

export default PaymentEventHandler;
