export const MESSAGE_TYPES = [
  "text",
  "img",
  "arxiv-scholar",
  "google-scholar",
  "pubmed-scholar",
  "cnki-scholar",
  "wanfang-scholar",
  "wiley-scholar",
  "science-scholar",
  "suggestion-scholar",
  "github-QA",
  "fulltext-search",
  ,
  ,
  ,
  ,
  ,
  ,
  ,
  ,
  "music",
];
