import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Descriptions, Dropdown, Typography } from "antd";
import { FilePdfOutlined, LoadingOutlined, SendOutlined } from "@ant-design/icons";
import cls from "classnames";
import useScreenSize from "@/hooks/useScreenSize";

const { Paragraph } = Typography;

function SuggestionCard({ props, index }) {
  const {
    abstract,
    author,
    citationCount, // 引用次数
    corpusId, // 语料库IDx
    externalIds, // 外部IDx
    fieldOfStudy, // 研究领域x
    influentialCitationCount, // 有影响力的引用次数x
    isOpenAccess, // 是否开放获取x
    journal, // 期刊
    openAccessPdf, // 开放获取PDF
    paperId, // 论文ID x
    publicationDate, // 出版日期
    publicationTypes, // 出版类型x
    publicationVenue, // 出版机构  x
    referenceCount, // 引用次数x
    s2FieldsOfStudy, // S2研究领域x
    title,
    url,
    venue, // 出版机构
    year, // x
  } = props;
  const dispatch = useDispatch();
  const { middle } = useScreenSize();
  const [uploading, setUploading] = useState();
  const { model } = useSelector((state) => state.token);

  const linkHandler = useCallback((url) => {
    window.open(url, "_blank");
  }, []);

  const importHandler = useCallback(() => {
    if (uploading) return;
    setUploading(true);
    const results = dispatch.chat.addScholar({
      pdf_url: openAccessPdf.url,
      title,
      model,
      info: props,
    });
    results.then((res) => setUploading(res));
  }, [dispatch, props, uploading, openAccessPdf, title, setUploading, model]);

  return (
    <div className={cls("suggestionCard", { widthStyle: !middle })}>
      <Descriptions
        column={2}
        title={
          <Paragraph
            className="title"
            ellipsis={{
              rows: 1,
              expandable: true,
              symbol: "更多",
              onExpand: (e) => e.stopPropagation(),
            }}
            title={`${index}. ${title}`}
            onClick={() => linkHandler(url)}
          >
            {index}. {title}
          </Paragraph>
        }
        extra={
          !!openAccessPdf && (
            <Dropdown.Button
              menu={{
                items: [
                  {
                    label: (
                      <Button
                        className={cls({ disabled: uploading })}
                        type="text"
                        icon={uploading ? <LoadingOutlined /> : <SendOutlined />}
                        onClick={importHandler}
                      >
                        导入文献分析
                      </Button>
                    ),
                    key: "1",
                  },
                ],
              }}
              getPopupContainer={(node) => node}
              onClick={() => linkHandler(openAccessPdf.url)}
            >
              <FilePdfOutlined /> 打开PDF
            </Dropdown.Button>
          )
        }
      >
        <Descriptions.Item
          span={2}
          label={<div className="label-name">作者</div>}
        >
          {author || "无"}
        </Descriptions.Item>
        <Descriptions.Item label={<div className="label-name">引用次数</div>}>
          {citationCount || "无"}
        </Descriptions.Item>
        <Descriptions.Item label={<div className="label-name">期刊</div>}>{journal}</Descriptions.Item>
        <Descriptions.Item
          label={<div className="label-name">出版日期</div>}
          span={2}
        >
          {publicationDate}
        </Descriptions.Item>
        <Descriptions.Item
          label={<div className="label-name">摘要</div>}
          span={2}
          className="abstract"
        >
          <Paragraph
            ellipsis={{
              rows: 3,
              expandable: true,
              symbol: "更多",
            }}
            title={abstract}
          >
            {abstract || "无"}
          </Paragraph>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}

export default SuggestionCard;
