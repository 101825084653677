import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Descriptions, Dropdown, Typography } from "antd";
import { FilePdfOutlined, LoadingOutlined, SendOutlined } from "@ant-design/icons";
import cls from "classnames";
import useScreenSize from "@/hooks/useScreenSize";

const { Paragraph, Text } = Typography;

function GoogleCard({ props, index }) {
  const {
    abstract,
    author,
    bib_id,
    journal,
    num_citations,
    number,
    pages,
    pub_type,
    pub_url,
    pub_year,
    publisher,
    title,
    venue,
    volume,
    eprint_url,
  } = props;
  const dispatch = useDispatch();
  const { middle } = useScreenSize();
  const { model } = useSelector((state) => state.token);

  const [uploading, setUploading] = useState(false);

  const linkHandler = useCallback((url) => {
    window.open(url, "_blank");
  }, []);

  const addHandler = useCallback(() => {
    setUploading(true);
    const results = dispatch.chat.addScholar({
      pdf_url: eprint_url,
      title,
      model,
      info: props,
    });
    results.then((res) => setUploading(res));
  }, [dispatch, props, eprint_url, title, setUploading, model]);

  return (
    <div className={cls("googleCard", { widthStyle: !middle })}>
      <Descriptions
        column={2}
        title={
          <Paragraph
            className="title"
            ellipsis={{
              rows: 1,
              expandable: true,
              symbol: "更多",
              onExpand: (e) => e.stopPropagation(),
            }}
            title={`${index}. ${title}`}
            onClick={() => linkHandler(pub_url)}
          >
            {index}. {title}
          </Paragraph>
        }
        extra={
          eprint_url && (
            <Dropdown.Button
              menu={{
                items: [
                  {
                    label: (
                      <Button
                        className={cls({ disabled: uploading })}
                        type="text"
                        icon={uploading ? <LoadingOutlined /> : <SendOutlined />}
                        onClick={addHandler}
                      >
                        导入文献分析
                      </Button>
                    ),
                    key: "1",
                  },
                ],
              }}
              getPopupContainer={(node) => node}
              onClick={() => linkHandler(eprint_url)}
            >
              <FilePdfOutlined /> 打开PDF
            </Dropdown.Button>
          )
        }
      />
      <div className="intruduce">
        <Paragraph
          className="auth-and-journal"
          ellipsis={{
            rows: 1,
            expandable: false,
          }}
          title={`${author} - ${journal}, ${pub_year}`}
        >
          {`${author} - ${journal}, ${pub_year}`}
        </Paragraph>
        <Text className="citations">被引用次数：{num_citations}</Text>
      </div>
      <Paragraph
        className="content"
        ellipsis={{
          rows: 3,
          expandable: true,
          symbol: "more",
        }}
        title={abstract}
      >
        {abstract}
      </Paragraph>
    </div>
  );
}

export default GoogleCard;
