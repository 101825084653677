import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Space, message } from "antd";
import { CaretDownFilled } from "@ant-design/icons";
import cls from "classnames";
import { MODEL_ICONS, MODEL_TIPS, MODEL_LIST, getModelName } from "@/constants/model";

function HeadTongue() {
  const dispatch = useDispatch();
  const { model = "GPT4" } = useSelector((state) => state.token);

  const handleMenuClick = ({ key }) => {
    dispatch.token.update({ model: key });
    message.info(MODEL_TIPS[key]);
  };

  const items = MODEL_LIST.map((_model) => {
    return {
      label: _model.label,
      key: _model.value,
      icon: (
        <img
          alt={_model.value}
          style={{ width: 32, height: 32 }}
          src={MODEL_ICONS[_model.value]}
        />
      ),
      className: cls({ active: model === _model.value }),
    };
  });

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Dropdown
      menu={menuProps}
      className="tongue"
      getPopupContainer={(node) => node}
    >
      <Space size={0}>
        <img
          alt={model}
          src={MODEL_ICONS[model]}
        />
        <div className="title">{getModelName(model)}</div>
        <CaretDownFilled />
      </Space>
    </Dropdown>
  );
}

export default HeadTongue;
