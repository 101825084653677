import styles from "./index.module.less";

function TypingBubble() {
  return (
    <div className={styles.bubbleLeftWrapper}>
      <div
        className="bubble-container"
        style={{ flex: 1 }}
      >
        <div className="typing-bubble Bubble text">
          <span className="typing-cursor"></span>
        </div>
      </div>
    </div>
  );
}

export default TypingBubble;
