import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { data as dataService } from "@/services";
import { feedback } from "@/utils/index";
import { Popconfirm, Tooltip, message } from "antd";
import { ClearOutlined } from "@ant-design/icons";

function ClearButton({ sessionId, pathname }) {
  const dispatch = useDispatch();

  const clearHandler = useCallback(async () => {
    const res = await dataService.clearSession(sessionId);
    if (res.status === "OK") {
      message.success("操作成功！");
      dispatch[pathname.replace("/", "")].version();
    } else {
      feedback(res.data || "清空失败，请重试");
    }
  }, [sessionId, pathname]);

  return (
    sessionId && (
      <Popconfirm
        title={"确定要清空当前会话吗?"}
        onConfirm={clearHandler}
        onCancel={() => {}}
      >
        <Tooltip title="清空会话">
          <ClearOutlined />
        </Tooltip>
      </Popconfirm>
    )
  );
}

export default ClearButton;
