import { Button, Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import DownloadButton from "./DownloadButton";
import RemoveButton from "./RemoveButton";
import BulkButton from "./BulkButton";
import RenameButton from "./RenameButton";
import styles from "./index.module.less";

function SessionItemAction({ title, id, setEditing, afterRemove, setBulkByItemHandler }) {
  const items = [
    {
      key: "1",
      label: <RenameButton setEditing={setEditing} />,
    },
    {
      key: "2",
      label: (
        <DownloadButton
          title={title}
          id={id}
        />
      ),
    },
    {
      key: "3",
      label: (
        <BulkButton
          id={id}
          setBulkByItemHandler={setBulkByItemHandler}
        />
      ),
    },
    {
      key: "4",
      label: (
        <RemoveButton
          id={id}
          afterRemove={afterRemove}
        />
      ),
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      getPopupContainer={(node) => node}
      className={styles.sessionItemAction}
    >
      <Button
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        type="text"
      >
        <MoreOutlined />
      </Button>
    </Dropdown>
  );
}

export default SessionItemAction;
