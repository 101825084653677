import React from "react";
import { BarsOutlined } from "@ant-design/icons";
import { Button } from "antd";

function BulkButton({ id, setBulkByItemHandler }) {
  return (
    <Button
      block
      size="small"
      icon={<BarsOutlined />}
      onClick={(e) => {
        e.stopPropagation();
        setBulkByItemHandler({ id });
      }}
      type="text"
    >
      批量操作
    </Button>
  );
}

export default React.memo(BulkButton);
