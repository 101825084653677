import { Select, Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import styles from "../index.module.less";

function RatioSelect({ quickReply }) {
  const dispatch = useDispatch();
  const { ratio } = useSelector((state) => state.config);
  const options = [
    {
      label: "16:9",
      value: "16:9",
    },
    {
      label: "9:16",
      value: "9:16",
    },
    {
      label: "1:1",
      value: "1:1",
    },
    {
      label: "4:3",
      value: "4:3",
    },
    {
      label: "3:4",
      value: "3:4",
    },
    {
      label: "3:2",
      value: "3:2",
    },
    {
      label: "2:3",
      value: "2:3",
    },
  ];

  return (
    <div className={styles.selectorContainer}>
      <span className="selector-label">图像比例</span>
      <Select
        size="small"
        bordered={false}
        defaultValue={ratio}
        onChange={(value) => {
          dispatch.config.update({
            ratio: value,
          });
        }}
        style={{ width: "70px" }}
      >
        {options.map((item) => (
          <Select.Option
            key={item.value}
            value={item.value}
          >
            {item.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export default RatioSelect;
