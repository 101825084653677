class DocUploadEventHandler {
  constructor(store) {
    this._store = store;
    this._loopMap = new Map();
  }

  onMessage(message) {
    const { type, value } = message;
    if (type !== "end") {
      return;
    }
    this._store.dispatch.eventsource.updateUpload({
      ...this._store.getState().eventsource.uploadMap[value.id],
      ...value,
    });
  }
}

export default DocUploadEventHandler;
