import { Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeTheme } from "@/utils/index";
import ThemeIcons from "@/icons/ThemeIcons";
import style from "./index.module.less";

function ModeSwitch() {
  const dispatch = useDispatch();
  const { dark } = useSelector((state) => state.theme);
  const [theme, setTheme] = useState(dark);
  const onChange = useCallback(
    (v) => {
      setTheme(v);
    },
    [setTheme, theme]
  );

  useEffect(() => {
    dispatch.theme.update({
      dark: !!theme,
    });
  }, [theme, setTheme, dispatch]);

  useEffect(() => {
    changeTheme(dark);
  }, [dark]);

  return (
    <Tooltip
      title="切换亮暗主题"
      placement="right"
    >
      <span className={style.modeSwitch}>
        {/* <Switch
          defaultChecked={theme}
          onChange={onChange}
          checkedChildren={<ThemeIcons type="moon" />}
          unCheckedChildren={<ThemeIcons type="sun" />}
        /> */}
        {dark ? (
          <div onClick={() => onChange(false)}>
            <ThemeIcons type="moon" />
          </div>
        ) : (
          <div onClick={() => onChange(true)}>
            <ThemeIcons type="sun" />
          </div>
        )}
      </span>
    </Tooltip>
  );
}

export default ModeSwitch;
