import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, message, Modal } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useLockFn } from "ahooks";
import { feedback } from "@/utils/index";
import { data as dataService } from "@/services/data";
import { useNavigate } from "react-router-dom";

function RemoveButton({ id, afterRemove }) {
  const [removeLoading, setRemoveLoading] = useState(false);
  const dispatch = useDispatch();
  const { sessionId } = useSelector((state) => state.chat);
  const navigate = useNavigate();

  const confirmHandler = useLockFn(async () => {
    setRemoveLoading(true);
    try {
      const res = await dataService.bulkRemoveSession({ ids: [id] });
      if (res.status === "OK") {
        dispatch.chat.version();
        afterRemove({ id });
        message.success("操作成功");
        if (sessionId === id) {
          dispatch.chat.resetSession();
          navigate("/chat");
        }
      }
    } catch (err) {
      setRemoveLoading(false);
      feedback(err.data);
    }
  });

  const confirmModal = () => {
    Modal.confirm({
      title: "删除",
      icon: <ExclamationCircleOutlined />,
      content: "确认删除当前会话吗？",
      okText: "确认",
      cancelText: "取消",
      onOk: confirmHandler,
    });
  };

  return (
    <>
      <Button
        size="small"
        icon={<DeleteOutlined />}
        type="text"
        onClick={(e) => {
          confirmModal();
          e.stopPropagation();
          e.preventDefault();
        }}
        block
        danger
      >
        删除
      </Button>
    </>
  );
}

export default RemoveButton;
