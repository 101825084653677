import { forwardRef } from "react";
import { useMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import UploadButton from "@/components/common/GptComposer/UploadButton";
import OutlineButton from "@/components/common/GptComposer/OutlineButton";
import OnlineButton from "@/components/common/GptComposer/OnlineButton";
import RefineButton from "@/components/common/GptComposer/RefineButton";
import EvidenceButton from "@/components/common/GptComposer/EvidenceEditor/EvidenceButton";
import PhraseBookButton from "@/components/common/GptComposer/PhraseBookEditor/PhraseBookButton";
import SheetConfigButton from "@/components/common/GptComposer/SheetConfigEditor/SheetConfigButton";
import DBSelect from "@/components/common/AdditionalConfig/DBSelect";
import AnalysisType from "@/components/common/AdditionalConfig/AnalysisType";
import KnowledgeSelect from "@/components/common/AdditionalConfig/KnowledgeSelect";
import LanguageSelect from "@/components/common/AdditionalConfig/LanguageSelect";
import PolishType from "@/components/common/AdditionalConfig/PolishType";
import RatioSelect from "@/components/common/AdditionalConfig/RatioSelect";
import VoiceSelect from "@/components/common/AdditionalConfig/VoiceSelect";
import { chatConfig, documentConfig } from "./config";

const CustomToolbar = forwardRef((props, ref) => {
  const {
    quickReply,
    text,
    setText,
    setFileList,
    fileList,
    setSendDisable,
    onSend,
    onOutlineSend,
    onRefineSend,
    appendMsg,
    messageList,
    phraseBtnRef,
  } = props;
  const chatMatch = useMatch("/chat");
  const documentMatch = useMatch("/document");
  const { sessionType } = useSelector((state) => state.document);
  const { quickReplyMode } = useSelector((state) => state.chat);
  let tools = chatMatch ? chatConfig(quickReply) : documentMatch ? documentConfig(sessionType, quickReplyMode) : [];
  return (
    <div className="chatBottom-custom-toolbar">
      {tools.map((item) => {
        switch (item) {
          case "language":
            return <LanguageSelect key={item} />;
          case "knowledge":
            return <KnowledgeSelect key={item} />;
          case "analysisType":
            return <AnalysisType key={item} />;
          case "dbSelect":
            return <DBSelect key={item} />;
          case "evidence":
            return <EvidenceButton key={item} />;
          case "polish":
            return <PolishType key={item} />;
          case "ratio":
            return <RatioSelect key={item} />;
          case "voice":
            return <VoiceSelect key={item} />;
          case "online":
            return <OnlineButton key={item} />;
          case "upload":
            return (
              <UploadButton
                quickReply={quickReply}
                setFileList={setFileList}
                fileList={fileList}
                setSendDisable={setSendDisable}
                callback={(_fileList) => {
                  onSend("file", { text, fileList: _fileList });
                  setText("");
                  setFileList([]);
                }}
                key={item}
              />
            );
          case "outline":
            return (
              <OutlineButton
                initialValue={text}
                quickReply={quickReply}
                onOutlineSend={onOutlineSend}
                setText={setText}
                key={item}
                appendMsg={appendMsg}
                messageList={messageList}
              ></OutlineButton>
            );
          case "refine":
            return (
              <RefineButton
                initialValue={text}
                quickReply={quickReply}
                onRefineSend={onRefineSend}
                key={item}
                appendMsg={appendMsg}
                messageList={messageList}
              ></RefineButton>
            );
          case "phrase":
            return (
              <PhraseBookButton
                key={item}
                phraseBtnRef={phraseBtnRef}
              />
            );
          case "sheet":
            return <SheetConfigButton key={item} />;
          default:
            return null;
        }
      })}
    </div>
  );
});

CustomToolbar.displayName = "CustomToolbar";
export default CustomToolbar;
