import { Skeleton } from "antd";
import styles from "./index.module.less";

function LoadingBubble() {
  return (
    <div style={{ flex: 1 }}>
      <div
        className={styles.bubbleRightWrapper}
        style={{ marginBottom: 24 }}
      >
        <div className="bubble-container loading-bubble">
          <Skeleton
            active
            avatar
            paragraph={{
              rows: 1,
            }}
          />
        </div>
      </div>
      <div className={styles.bubbleLeftWrapper}>
        <div className="bubble-container loading-bubble">
          <Skeleton
            active
            avatar
            paragraph={{
              rows: 4,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default LoadingBubble;
