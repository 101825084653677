export const up = (
  <svg
    t="1723781466756"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="3687"
  >
    <path
      d="M698.8 337.6H325.2c-18.4 0-33.5-14.4-33.5-32s15.1-32 33.5-32h373.7c18.4 0 33.5 14.4 33.5 32-0.1 17.6-15.1 32-33.6 32z"
      fill="#ffffff"
      p-id="3688"
    ></path>
    <path
      d="M508.4 547.8l1.8-1.8-1.8 1.8zM508.2 545.8l2.2 2.2c-0.7-0.8-1.4-1.5-2.2-2.2zM511.1 508.7l1.8 1.8-1.8-1.8z"
      fill="#FFFFFF"
      p-id="3689"
    ></path>
    <path
      d="M510.9 510.7l2.2-2.2c-0.8 0.7-1.5 1.4-2.2 2.2z"
      fill="#FFFFFF"
      p-id="3690"
    ></path>
    <path
      d="M544 472.4v246c0 17.6-14.4 32-32 32s-32-14.4-32-32v-246c0-17.6 14.4-32 32-32s32 14.4 32 32z"
      fill="#ffffff"
      p-id="3691"
    ></path>
    <path
      d="M511.9 379c-8.3 0-15.8 3.1-21.5 8.3l-2.2 2.2-21.5 21.5L311 566.7c-12.4 12.4-12.4 32.8 0 45.3 12.4 12.4 32.8 12.4 45.3 0L512 456.2l155.8 155.7c12.4 12.4 32.8 12.4 45.3 0 12.4-12.4 12.4-32.8-0.1-45.2L557.3 411l-21.8-21.8-1.8-1.8c-5.7-5.3-13.4-8.5-21.8-8.4z"
      fill="#ffffff"
      p-id="3692"
    ></path>
  </svg>
);

export const down = (
  <svg
    t="1723781671911"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="4093"
  >
    <path
      d="M325.2 686.39999999L698.8 686.39999999c18.39999999 0 33.5 14.4 33.5 32.00000001s-15.1 32-33.5 32l-373.7 0c-18.39999999 0-33.5-14.4-33.5-32 0.1-17.6 15.1-32 33.6-32.00000001z"
      fill="#ffffff"
      p-id="4094"
    ></path>
    <path
      d="M515.6 476.2l-1.79999999 1.8 1.79999999-1.8zM515.8 478.2l-2.2-2.2c0.7 0.8 1.4 1.5 2.2 2.2zM512.9 515.3l-1.8-1.8 1.8 1.8z"
      fill="#ffffff"
      p-id="4095"
    ></path>
    <path
      d="M513.09999999 513.3l-2.19999999 2.19999999c0.8-0.7 1.5-1.4 2.19999999-2.19999999z"
      fill="#ffffff"
      p-id="4096"
    ></path>
    <path
      d="M480 551.6l0-246c0-17.6 14.4-32 32-31.99999999s32 14.4 32 31.99999999l0 246c0 17.6-14.4 32-32 32s-32-14.4-32-32z"
      fill="#ffffff"
      p-id="4097"
    ></path>
    <path
      d="M512.1 645c8.29999999 0 15.8-3.1 21.5-8.3l2.2-2.2 21.5-21.5L713 457.3c12.4-12.4 12.4-32.8 0-45.3-12.4-12.4-32.8-12.4-45.3 0L512 567.8l-155.8-155.7c-12.4-12.4-32.8-12.4-45.3 0-12.4 12.4-12.4 32.8 0.1 45.2L466.7 613l21.8 21.8 1.8 1.8c5.7 5.3 13.4 8.5 21.8 8.4z"
      fill="#ffffff"
      p-id="4098"
    ></path>
  </svg>
);
