/**
 * 整个应用内数据，尽量只请求一次的数据
 */
export const app = {
  state: {
    // 右侧帮助按钮F&Q、视频教程链接地址
    chatFaqUrlList: [],
    chatTutorUrlList: [],
    // 插件（根据用户pin状态，分为默认的和pin两个list）
    chatPromptList: [],
    chatPromptGroup: [],
    chatPromptLoading: true,
    chatPromptVersion: Date.now(),
    // 插件placeholder
    chatPlaceholderList: [],
    // 文献快捷方式，一次请求不会变
    documentQuickTypeList: [],
    documentQuickTypeLoading: true,
  },
  reducers: {
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
