import { memo } from "react";
import { Tooltip } from "antd";
import style from "./index.module.less";
import { useMatch } from "react-router-dom";

function MessageQuestionType({ questionType, config }) {
  const matchAtDocument = useMatch("/document");
  const { pubmedTypes, db, analysisType, language, online, knowledge, polish_type, voice, json_schema } = config || [];
  let fields = "";
  if (json_schema && json_schema.fields) {
    fields = json_schema.fields
      ?.map((v) => {
        return v.name;
      })
      .join(",");
  }
  return (
    <div
      className="messageQuestionType"
      style={{ display: "flex" }}
    >
      {matchAtDocument && (
        <p className={style.bubbleQuestionType}>
          <Tooltip title={`提问模式：${questionType}`}>
            <span>{questionType}</span>
          </Tooltip>
        </p>
      )}
      {pubmedTypes && pubmedTypes.length > 0 && (
        <p className={style.bubbleQuestionType}>
          <Tooltip title={`PubMed分类：${pubmedTypes.join()}`}>
            <span>{pubmedTypes.join()}</span>
          </Tooltip>
        </p>
      )}
      {db && (
        <p className={style.bubbleQuestionType}>
          <Tooltip title={`网络文献：${db}`}>
            <span>{db}</span>
          </Tooltip>
        </p>
      )}
      {analysisType && (
        <p className={style.bubbleQuestionType}>
          <Tooltip title={`分析类型：${analysisType}`}>
            <span>{analysisType}</span>
          </Tooltip>
        </p>
      )}
      {language && (
        <p className={style.bubbleQuestionType}>
          <Tooltip title={`目标语言：${language}`}>
            <span>{language}</span>
          </Tooltip>
        </p>
      )}
      {online && (
        <p className={style.bubbleQuestionType}>
          <Tooltip title={"在线搜索"}>
            <span>{"在线搜索"}</span>
          </Tooltip>
        </p>
      )}
      {knowledge && (
        <p className={style.bubbleQuestionType}>
          <Tooltip title={`个人文献：${knowledge["name"]}`}>
            <span>{knowledge["name"]}</span>
          </Tooltip>
        </p>
      )}
      {polish_type && (
        <p className={style.bubbleQuestionType}>
          <Tooltip title={`润色风格：${polish_type}`}>
            <span>{polish_type}</span>
          </Tooltip>
        </p>
      )}
      {voice && (
        <p className={style.bubbleQuestionType}>
          <Tooltip title={`声音：${voice}`}>
            <span>{voice}</span>
          </Tooltip>
        </p>
      )}
      {json_schema && (
        <p className={style.bubbleQuestionType}>
          <Tooltip title={"提取配置"}>
            <span>{`${json_schema["name"]}: ${fields}`}</span>
          </Tooltip>
        </p>
      )}
    </div>
  );
}

export default MessageQuestionType;
