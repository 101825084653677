import { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Skeleton, Typography, List, Tooltip, Button, message, Col, Row } from "antd";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { useRequest } from "ahooks";
import { data as dataService } from "@/services";
import { feedback } from "@/utils/index";
import { isEmpty } from "lodash-es";
import useScreenSize from "@/hooks/useScreenSize";
import PluginCardIcons from "@/icons/PluginCardIcons/index";
import styles from "./index.module.less";
import cls from "classnames";
import Scrollbars from "react-custom-scrollbars-2";

const QuestionTypeCard = ({ data, onClick, topping, untopping, createNewChat, toppingLoading, untoppingLoading }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <div className={styles.questionTypeCard}>
      <Row gutter={[16, 16]}>
        <Col
          md={8}
          lg={8}
          xl={6}
        >
          <div
            className="simpleChatCardItem"
            onClick={createNewChat}
            onMouseEnter={() => {
              setIsHover(true);
            }}
            onMouseLeave={() => {
              setIsHover(false);
            }}
          >
            <div className="simpleChat">
              {isHover ? <PluginCardIcons type="新会话" /> : <PluginCardIcons type="新会话2" />}
              <div className="simpleChatText">新建普通对话</div>
            </div>
          </div>
        </Col>
        {data.map((item, index) => {
          return (
            <Col
              md={8}
              lg={8}
              xl={6}
              key={index}
            >
              <div
                className="questionTypeCardItem"
                onClick={() => onClick(item)}
              >
                <div className="question-type-item-content">
                  <div className="question-type-item-avatar-wrapper">
                    <div className="question-type-item-avatar">
                      <div className="avatar-bg">
                        <PluginCardIcons type={item.short_name} />
                      </div>
                    </div>
                  </div>
                  <div className="question-type-item-info">
                    <div className="question-type-item-title">{item.short_name}</div>
                    <Typography.Paragraph
                      type="secondary"
                      ellipsis={{ rows: 3 }}
                      style={{ minHeight: "44px" }}
                    >
                      {item.description_short}
                    </Typography.Paragraph>
                  </div>
                </div>
                <div className="card-actions">
                  <Tooltip
                    title={item.has_pin ? "取消收藏" : "收藏"}
                    placement="left"
                    getPopupContainer={(node) => document.body}
                    mouseEnterDelay={0.8}
                    mouseLeaveDelay={0}
                  >
                    {item.has_pin ? (
                      <Button
                        type="text"
                        icon={<HeartFilled style={{ color: "red" }} />}
                        loading={untoppingLoading}
                        onClick={(e) => {
                          e.stopPropagation();
                          item.has_pin ? untopping(item) : topping(item);
                        }}
                      />
                    ) : (
                      <Button
                        type="text"
                        icon={<HeartOutlined />}
                        loading={toppingLoading}
                        onClick={(e) => {
                          e.stopPropagation();
                          item.has_pin ? untopping(item) : topping(item);
                        }}
                      />
                    )}
                  </Tooltip>
                </div>
                <div className="cover">
                  <PluginCardIcons type="moren" />
                  <span>开始对话</span>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

const MobileQuestionTypeCard = ({
  data,
  onClick,
  topping,
  untopping,
  createNewChat,
  toppingLoading,
  untoppingLoading,
}) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <div className={styles.mobileQuestionTypeCard}>
      <Row
        justify="start"
        gutter={[16, 16]}
      >
        <Col
          md={8}
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className="simpleChatCardItem"
            onClick={createNewChat}
            onMouseEnter={() => {
              setIsHover(true);
            }}
            onMouseLeave={() => {
              setIsHover(false);
            }}
          >
            <div className="simpleChat">
              {isHover ? <PluginCardIcons type="新会话" /> : <PluginCardIcons type="新会话2" />}
              <div className="simpleChatText">新建普通对话</div>
            </div>
          </div>
        </Col>
        {data.map((item, index) => {
          return (
            <Col
              md={8}
              xs={12}
              key={index}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                className="questionTypeCardItem"
                onClick={() => onClick(item)}
              >
                <div className="question-type-item-content">
                  <div className="question-type-item-avatar-wrapper">
                    <div className="question-type-item-avatar">
                      <div className="avatar-bg">
                        <PluginCardIcons type={item.short_name} />
                      </div>
                    </div>
                  </div>
                  <div className="question-type-item-info">
                    <div className="question-type-item-title">{item.short_name}</div>
                    <Typography.Paragraph
                      type="secondary"
                      ellipsis={{ rows: 3 }}
                      style={{ minHeight: "44px" }}
                    >
                      {item.description_short}
                    </Typography.Paragraph>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

function ChatHome() {
  const dispatch = useDispatch();
  const { middle } = useScreenSize();
  const { chatPromptGroup, chatPromptLoading } = useSelector((state) => state.app);
  const { activeTabKey } = useSelector((state) => state.chat);
  const { userGuide } = useSelector((state) => state.user);
  const [activeKey, setActiveKey] = useState(localStorage.getItem("tabActiveKey") || "question-type-card-1");
  const [activeData, setActiveData] = useState();

  useEffect(() => {
    if (activeKey === "question-type-card-0") {
      setActiveData(userPinnedChatPrompt);
    } else if (!isEmpty(chatPromptGroup)) {
      const num = activeKey.charAt(activeKey.length - 1) - 1;
      setActiveData(chatPromptGroup[num] && chatPromptGroup[num].length > 1 ? chatPromptGroup[num][1] : []);
    }
  }, [chatPromptGroup]);

  useEffect(() => {
    if (!activeTabKey || !userGuide) return;
    let data = [];
    if (activeTabKey === "question-type-card-0") {
      data = userPinnedChatPrompt;
    } else if (!isEmpty(chatPromptGroup)) {
      const num = activeTabKey.charAt(activeKey.length - 1) - 1;
      data = chatPromptGroup[num][1];
    }
    handleTabChange(activeTabKey, data);
  }, [activeTabKey]);

  const handleTabChange = (key, data) => {
    setActiveKey(key);
    setActiveData(data);
    localStorage.setItem("tabActiveKey", key);
  };

  // 选择插件
  const cardClickHandler = useCallback(
    (item) => {
      dispatch.chat.update({
        quickReply: item.short_name,
        replyVersion: Date.now(),
        placeHolderText: item.placeholder,
        chatAtHome: false,
      });
    },
    [dispatch]
  );

  // 新建对话
  const createNewChat = useCallback(() => {
    dispatch.chat.update({
      chatAtHome: false,
    });
  });

  // 收藏list
  const userPinnedChatPrompt = [];
  (chatPromptGroup || []).forEach((group) => {
    if (group && group.length > 1 && group[1] && group[1].length) {
      group[1].forEach((prompt) => {
        if (prompt.has_pin) {
          userPinnedChatPrompt.push(prompt);
        }
      });
    }
  });

  // 取消收藏
  const { run: untopping, loading: untoppingLoading } = useRequest(
    (params) => dataService.untoppingTypeCard({ prompt: params.id }),
    {
      manual: true,
      debounceWait: 500,
      onSuccess: () => {
        message.success("取消收藏");
        dispatch.app.update({
          chatPromptVersion: Date.now(),
        });
      },
      onError: (err) => feedback(err.data || "操作失败"),
    }
  );

  // 收藏
  const { run: topping, loading: toppingLoading } = useRequest(
    (params) => dataService.toppingTypeCard({ prompt: params.id }),
    {
      manual: true,
      debounceWait: 500,
      onSuccess: () => {
        message.success("收藏成功");
        dispatch.app.update({
          chatPromptVersion: Date.now(),
        });
      },
      onError: (err) => feedback(err.data || "操作失败"),
    }
  );

  return (
    <div className={styles.chatHome}>
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        style={{ height: "100%" }}
        className="chathome-scrollbar"
      >
        <Skeleton loading={chatPromptLoading && isEmpty(chatPromptGroup)}>
          {middle ? (
            <div className="chatHome-tabs-container">
              <div className="chatHome-tabs-wrapper">
                <div
                  className={cls("chatHome-tabs-item tour-tab-0", { active: activeKey === `question-type-card-0` })}
                  key={"question-type-card-0"}
                  onClick={() => handleTabChange("question-type-card-0", userPinnedChatPrompt)}
                >
                  <div className="tab-icon">
                    <PluginCardIcons type="我的收藏" />
                  </div>
                  <div className="tab-name">我的收藏</div>
                </div>
                {chatPromptGroup.map(([type, _data], index) => {
                  return (
                    <div
                      className={cls("chatHome-tabs-item", `tour-tab-${index + 1}`, {
                        active: activeKey === `question-type-card-${index + 1}`,
                      })}
                      key={`question-type-card-${index + 1}`}
                      onClick={() => handleTabChange(`question-type-card-${index + 1}`, _data)}
                    >
                      {middle && (
                        <div className="tab-icon">
                          <PluginCardIcons type={type} />
                        </div>
                      )}
                      <div className="tab-name">{middle ? type : type.slice(0, 2)}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            // mobile tabs
            <div className="mobile-chatHome-tabs-wrapper">
              <div className="mobile-chatHome-tabs">
                <div
                  className={cls("mobile-chatHome-tabs-item tour-tab-0", {
                    active: activeKey === "question-type-card-0",
                  })}
                  key={"question-type-card-0"}
                  onClick={() => handleTabChange("question-type-card-0", userPinnedChatPrompt)}
                >
                  <div className={cls("mobile-tab-icon", { active: activeKey === "question-type-card-0" })}>
                    <PluginCardIcons type="我的收藏" />
                  </div>
                  <div className={cls("mobile-tab-name", { active: activeKey === "question-type-card-0" })}>
                    我的收藏
                  </div>
                </div>
                {chatPromptGroup.map(([type, _data], index) => {
                  return (
                    <div
                      className={cls("mobile-chatHome-tabs-item", `tour-tab-${index + 1}`, {
                        active: activeKey === `question-type-card-${index + 1}`,
                      })}
                      key={`question-type-card-${index + 1}`}
                      onClick={() => handleTabChange(`question-type-card-${index + 1}`, _data)}
                    >
                      <div
                        className={cls("mobile-tab-icon", { active: activeKey === `question-type-card-${index + 1}` })}
                      >
                        <PluginCardIcons type={type} />
                      </div>
                      <div
                        className={cls("mobile-tab-name", { active: activeKey === `question-type-card-${index + 1}` })}
                      >
                        {middle ? type : type.slice(0, 2)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div
            className={cls({
              "chatHome-tabs-content": middle,
              "mobile-chatHome-tabs-content": !middle,
            })}
          >
            {middle && activeData ? (
              <QuestionTypeCard
                data={activeData}
                topData={userPinnedChatPrompt}
                onClick={cardClickHandler}
                topping={topping}
                untopping={untopping}
                createNewChat={createNewChat}
                toppingLoading={toppingLoading}
                untoppingLoading={untoppingLoading}
              />
            ) : null}
            {!middle && activeData ? (
              <MobileQuestionTypeCard
                data={activeData}
                topData={userPinnedChatPrompt}
                onClick={cardClickHandler}
                topping={topping}
                untopping={untopping}
                createNewChat={createNewChat}
                toppingLoading={toppingLoading}
                untoppingLoading={untoppingLoading}
              />
            ) : null}
          </div>
        </Skeleton>
      </Scrollbars>
    </div>
  );
}

export default ChatHome;
