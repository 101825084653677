import axios from "axios";

import { store } from "@/store";

const instance = axios.create({});
instance.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.token?.jwt;
  if (token) {
    config.headers.Authorization = `JWT ${token}`;
  }
  return config;
});

let prefixUrl = "";
const query = (method, endpoint, parameters, isUpload, isDownload) => {
  let url = `${prefixUrl}${endpoint}`;
  const headers = {};
  if (isUpload) {
    headers["Content-Type"] = "multipart/form-data";
  } else {
    headers["Content-Type"] = "application/json";
  }
  const config = {
    method,
    url,
    headers,
  };
  if (method === "post" || method === "put" || method === "patch" || method === "delete") {
    config.data = parameters;
  } else {
    config.params = parameters;
  }
  return instance(config)
    .then((response) => {
      if (isDownload) {
        if (response.data.code) {
          return Promise.reject(response);
        } else {
          return Promise.resolve(response.data);
        }
      } else {
        const code = response.data.code;
        if (code >= 200 && code < 300) {
          return Promise.resolve(response.data);
        } else {
          return Promise.reject(response);
        }
      }
    })
    .catch((error) => {
      if (error.response) {
        const { status, data } = error.response;
        switch (status) {
          case 500:
            return Promise.reject({
              data: "系统错误， 请重试！",
              code: 500,
              status: "ERROR",
            });
          case 401:
            store.dispatch.token.init();
            return Promise.reject({
              data: "登录已过期，请重新登录",
              code: 401,
              status: "ERROR",
            });
          case 402:
            return Promise.reject({
              data: error.response.data?.data,
              code: 402,
              status: "ERROR",
            });
          case 400:
          case 403:
            return Promise.reject({
              data: data?.data,
              code: 403,
              status: "ERROR",
            });
          case 405:
          case 404:
            return Promise.reject(data);
          default:
            return Promise.reject({
              data: "系统错误， 请重试！",
              code: 500,
              status: "ERROR",
            });
        }
      } else if (error.request) {
        if (error.data.code) {
          // http status === 200, response data status !== 200
          switch (error.data.code) {
            case 500:
              return Promise.reject({
                data: "系统错误， 请重试！",
                code: 500,
                status: "ERROR",
              });
            case 401:
              store.dispatch.token.init();
              return Promise.reject({
                data: "登录已过期，请重新登录",
                code: 401,
                status: "ERROR",
              });
            case 402:
              return Promise.reject({
                data: error.response.data?.data,
                code: 402,
                status: "ERROR",
              });
            case 400:
            case 404:
            case 403:
              return Promise.reject({
                data: error.response.data?.data,
                code: 403,
                status: "ERROR",
              });
            case 405:
              return Promise.reject(error.data);
            default:
              return Promise.reject({
                data: "系统错误， 请重试！",
                code: 500,
                status: "ERROR",
              });
          }
        } else {
          return Promise.reject({
            data: "请求出错， 请重试！",
            code: 500,
            status: "ERROR",
          });
        }
      } else {
        return Promise.reject({
          data: "请求出错， 请重试！",
          code: 500,
          status: "ERROR",
        });
      }
    });
};

export const get = (endpoint, parameters) => {
  return query("get", endpoint, parameters);
};

export const post = (endpoint, parameters) => {
  return query("post", endpoint, parameters);
};

export const patch = (endpoint, parameters) => {
  return query("patch", endpoint, parameters);
};

export const put = (endpoint, parameters) => {
  return query("put", endpoint, parameters);
};

export const remove = (endpoint, parameters) => {
  return query("delete", endpoint, parameters);
};

export const upload = (endpoint, parameters) => {
  return query("post", endpoint, parameters, true);
};

export const download = (endpoint, parameters) => {
  return query("get", endpoint, parameters, false, true);
};
