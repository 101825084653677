export const MODEL_TIPS = {
  GPT4_MINI: "响应速度快，每次普通对话消耗 1 点，登录后免费(不含文献)",
  CLAUDE_V3_OPUS: "擅长文字，每次普通对话消耗 30 点",
  CLAUDE_V3_SONNET: "擅长代码等，每次普通对话消耗 20 点",
  CLAUDE_V3_HAIKU: "响应速度快，每次普通对话消耗 1 点",
  GPT4: "擅长文字，每次普通对话消耗 20 点",
  // O1_MINI: "擅长推理、代码，知识略有欠缺，每次普通对话消耗 20 点",
  O1: "擅长推理、代码，效果极佳，每次普通对话消耗 100 点",
  ERNIE_TURBO: "中文支持好，回答速度快，登录后免费(不含文献)",
  GEMINI_FLASH: "回答速度快，每次普通对话消耗 10 点",
};

export const MODEL_LIST = [
  {
    label: "GPT4o mini",
    value: "GPT4_MINI",
  },
  {
    label: "GPT4o",
    value: "GPT4",
  },
  // {
  //   label: "OpenAI o1 mini",
  //   value: "O1_MINI",
  // },
  {
    label: "OpenAI o1",
    value: "O1",
  },
  {
    label: "Haiku",
    value: "CLAUDE_V3_HAIKU",
  },
  {
    label: "Sonnet",
    value: "CLAUDE_V3_SONNET",
  },
  {
    label: "Opus",
    value: "CLAUDE_V3_OPUS",
  },
  {
    label: "Gemini",
    value: "GEMINI_FLASH",
  },
  {
    label: "文心一言",
    value: "ERNIE_TURBO",
  },
];

export const getModelName = (key) => {
  const item = MODEL_LIST.find((x) => x.value === key);
  return item && item.label ? item.label : key;
};

export const MODEL_ICONS = {
  GPT4_MINI: "/gpt35.png",
  O1_MINI: "/o1.png",
  O1: "/o1.png",
  CLAUDE_V3_HAIKU: "/haiku.jpeg",
  CLAUDE_V3_SONNET: "/sonnet.jpeg",
  CLAUDE_V3_OPUS: "/opus.jpeg",
  GPT4: "/gpt4.png",
  ERNIE_TURBO: "/ERNIE_TURBO.jpg",
  GEMINI_FLASH: "/GEMINI.png",
};

export const MODEL_FEATURES = {
  GPT4_MINI: "GPT4o mini, 适合解决简单问题，高要求或复杂问题请选择o1, GPT4o，不支持图片",
  CLAUDE_V3_OPUS: "Claude 3 Opus, Anthropic 上一代模型，能力不如Sonnet 3.5，支持图片",
  CLAUDE_V3_SONNET: "Claude 3.5 Sonnet, Anthropic 新一代模型，能力略差于GPT4o，支持图片",
  CLAUDE_V3_HAIKU: "Claude 3 Haiku, 响应快，创造力强，支持图片。复杂问题请选择o1, GPT4o",
  GPT4: "GPT4o, OpenAI 上一代最强大模型，适合处理文字类复杂问题，支持图片，如需代码、推理等请选择o1",
  // O1_MINI: "OpenAI o1 mini, 推理大模型，适合代码、数据处理、推理、理工科学等，不擅长已有知识问题，不支持图片",
  O1: "OpenAI o1 preview, 当前最强推理大模型，达到博士生水平，适合处理各类复杂问题，如代码、数据处理、推理、理工科学、论文撰写等，不支持图片",
  ERNIE_TURBO: "百度文心一言，适合简单中文问答，不支持图片。论文撰写等场景请选择o1, GPT4o",
  GEMINI_FLASH: "Gemini 2.0, Google最新出品超强人工智能大语言模型，支持图片",
};
