import { useCallback, useState } from "react";
import useScreenSize from "@/hooks/useScreenSize";
import { Button, Tooltip } from "antd";
import { useMatch } from "react-router-dom";
import { FileSearchOutlined } from "@ant-design/icons";
import EvidenceModal from "./EvidenceModal";

function EvidenceButton() {
  const { middle } = useScreenSize();
  const [open, setOpen] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const inputHandler = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <Tooltip
      placement="top"
      title={"输入PubMed检索策略，如未输入则系统根据输入的问题自动生成"}
      open={openTooltip}
    >
      {middle ? (
        <Button
          shape="round"
          size="small"
          className="input-tools-btn"
          onClick={inputHandler}
          onMouseEnter={() => setOpenTooltip(true)}
          onMouseLeave={() => setOpenTooltip(false)}
        >
          检索策略
        </Button>
      ) : (
        <Button
          size="large"
          type="text"
          icon={<FileSearchOutlined style={{ color: "var(--brand-1)" }} />}
          onClick={inputHandler}
          onMouseEnter={() => setOpenTooltip(true)}
          onMouseLeave={() => setOpenTooltip(false)}
        ></Button>
      )}
      <EvidenceModal
        open={open}
        setOpen={setOpen}
      ></EvidenceModal>
    </Tooltip>
  );
}

export default EvidenceButton;
